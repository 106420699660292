import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AcquirerCreate from './AcquirerCreate/AcquirerCreate';
import AcquirerEdit from './AcquirerEdit/AcquirerEdit';
import AcquirersList from './AcquirersList/AcquirersList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Acquirers = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Acquirers">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><AcquirersList /></Route>
                    <Route exact path={`${path}/new`}><AcquirerCreate /></Route>
                    <Route exact path={`${path}/edit/:acquirerId`}><AcquirerEdit /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default Acquirers;
