import { Rates } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import copySlice from './rates/copy';

const {
    initialState,
    createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(Rates);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        createReducer, getReducer, listReducer,
        updateReducer, deleteReducer,
        copySlice.copyRatesReducer
    ]);
}

/* Export CRUD actions */
export const createRate  = createAction;
export const loadRate    = getAction;
export const loadRates   = listAction;
export const updateRate  = updateAction;
export const deleteRate  = deleteAction;
export const copyRates   = (sourceId, targetId) => dispatch => {
    copySlice.copyRatesAction(sourceId, targetId)(dispatch).then(() => {
        dispatch(loadRates({'account_id': targetId}));
    });
};
