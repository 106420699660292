import { Transactions } from '../../../services';

const GETTING_REVENUES_STATS         = `pixoo/transactions/GETTING_REVENUES_STATS`;
const GETTING_REVENUES_STATS_SUCCESS = `pixoo/transactions/GETTING_REVENUES_STATS_SUCCESS`;
const GETTING_REVENUES_STATS_FAILURE = `pixoo/transactions/GETTING_REVENUES_STATS_FAILURE`;

/* reducer */
const revenuesStatsReducer = (state, action) => {
    switch (action.type) {
            case GETTING_REVENUES_STATS:
                return {
                    ...state,
                    isGettingRevenuesStats: true,
                    hasGettingRevenuesStatsError: false
                };
            case GETTING_REVENUES_STATS_SUCCESS:
                return {
                    ...state,
                    isGettingRevenuesStats: false,
                    hasGettingRevenuesStatsError: false,
                    revenuesStats: action.revenuesStats
                };
            case GETTING_REVENUES_STATS_FAILURE:
                return {
                    ...state,
                    isGettingRevenuesStats: false,
                    hasGettingRevenuesStatsError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function gettingRevenuesStats() { return { type: GETTING_REVENUES_STATS } }
function gettingRevenuesStatsSuccess(revenuesStats) { return { type: GETTING_REVENUES_STATS_SUCCESS, revenuesStats: revenuesStats } }
function gettingRevenuesStatsFailure(err) { return { type: GETTING_REVENUES_STATS_FAILURE, error: err } }
const getRevenuesStatsAction = (filters) => {
    return (dispatch) => {
        dispatch(gettingRevenuesStats());
        Transactions.getRevenuesStats(filters)
            .then(data => {
                const { revenues_stats } = data;
                dispatch(gettingRevenuesStatsSuccess(revenues_stats));
            })
            .catch(err => {
                dispatch(gettingRevenuesStatsFailure(err))
            });
    }
}

/* Export reducer */
export default { revenuesStatsReducer,  getRevenuesStatsAction };
