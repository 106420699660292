import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { mdiPlusCircleOutline, mdiCreditCardOutline, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import { createRate, loadRates, updateRate, deleteRate, copyRates } from '../../../redux/modules/rates';
import RateDetails from './RateDetails/RateDetails';
import RateForm from './RateForm/RateForm';
import moment from 'moment';
import './AccountRatesForm.css';

const AccountRatesForm = ({ accountId }) => {
    const [ copySource, setCopySource ]         = useState(null);
    const [ activePs, setActivePs ]             = useState(null);
    const [ activeAcquirer, setActiveAcquirer ] = useState(null);
    const [ activeCardType, setActiveCardType ] = useState(null);
    const [ activeRate, setActiveRate ]         = useState(null);

    const { accounts }        = useSelector(state => state.accounts);
    const { payment_systems } = useSelector(state => state.payment_systems);
    const { acquirers }       = useSelector(state => state.acquirers);
    const { card_types }      = useSelector(state => state.card_types);
    const { rates }           = useSelector(state => state.rates);

    const { t }         = useTranslation();

    const dispatch = useDispatch();
    useEffect(() => {
        if (accountId)
            dispatch(loadRates({ 'account_id': accountId }))
    }, [dispatch, accountId])

    const handleRateEdition = (values) => {
        dispatch(updateRate(values));
    };

    const addRate = (payment_system_id, acquirer_id, card_type_id) => {
        dispatch(createRate({
            account_id: accountId,
            start_at: moment().toISOString(),
            payment_system_fees: [{type: 'fixed', rate: 0}],
            acquirer_fees: [{type: 'fixed', rate: 0}],
            payment_system_id,
            acquirer_id,
            card_type_id
        }));
    };

    const showCardTypeContent = (cardTypeId) => {
        return setActiveCardType(activeCardType === cardTypeId ? null : cardTypeId);
    }

    const showRateForm = (rateId) => {
        setActiveRate(null);
        setTimeout(() => { setActiveRate(rateId); }, 0);
    };

    const removeRate = (rateId) => {
        dispatch(deleteRate(rateId));
    };

    const changedCopySource = (e) => {
        setCopySource(e.target.value);
    }

    const copyRatesFrom = (e) => {
        if (copySource)
            dispatch(copyRates(copySource, accountId));
    }

    const nbRates = (payment_system_id, acquirer_id, card_type_id) => {
        if (!rates)
            return 0;

        return rates.filter(rate => {
            return (
                (!payment_system_id || rate.payment_system_id === payment_system_id) &&
                (!acquirer_id || rate.acquirer_id === acquirer_id) &&
                (!card_type_id || rate.card_type_id === card_type_id)
            );
        }).length;
    }

    return (
        <div className="AccountRatesForm">
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ t('accounts.edit_rates_title') }</CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col className="text-center mb-3">
                            <div>
                                <InputGroup>
                                    <select className="form-control" onChange={ changedCopySource }>
                                        <option> { t('rates.copy_rates_from') } </option>
                                        { accounts && accounts.filter(account => account.id !== accountId).map(account => (
                                        <option value={ account.id }> { account.name } </option>
                                        ))}
                                    </select>
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={ copyRatesFrom } color="info">
                                            <Icon path={ mdiContentCopy } color="white" size="22" />
                                            { t('rates.copy') }
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mb-3">
                            <ButtonGroup>
                            {payment_systems && payment_systems.map(ps => (
                                <Button onClick={ () => { setActivePs(ps.id); setActiveAcquirer(null); setActiveCardType(null); }} color={ activePs === ps.id ? 'info' : 'dark' } size='sm'>
                                    { ps.name }{' '}
                                    ({ nbRates(ps.id) })
                                </Button>
                            ))}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {payment_systems && payment_systems.filter(ps => ps.id === activePs).map(ps => (
                        <>
                            <Row>
                                <Col className="text-center mb-3">
                                    <ButtonGroup>
                                    { acquirers && acquirers.filter(acquirer => acquirer.payment_system_id === ps.id).map(acquirer => (
                                        <Button onClick={ () => { setActiveAcquirer(acquirer.id); setActiveCardType(null); }} color={ activeAcquirer === acquirer.id ? 'info' : 'dark' } size='sm'>
                                            { acquirer.name }
                                            ({ nbRates(activePs, acquirer.id) })
                                        </Button>
                                    ))}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            { acquirers && acquirers.filter(acquirer => acquirer.id === activeAcquirer).map(acquirer => (
                                <>
                                    <div className="used-card-types">
                                        <h4>{ t('rates.active_card_types') }</h4>
                                        { card_types && card_types.filter(card_type => nbRates(activePs, activeAcquirer, card_type.id) > 0).map(card_type => (
                                            <>
                                                <div className={ `card-type-wrapper ${activeCardType === card_type.id ? 'active' : '' } `} size='sm'>
                                                    <div className="card-type-title" onClick={ (e) => { showCardTypeContent(card_type.id); }}>
                                                        <span className="icon">
                                                            <Icon path={ mdiCreditCardOutline } color="white" size="22" />
                                                        </span>
                                                        <span className="text">
                                                            { card_type.name }{' '}
                                                            ({ nbRates(activePs, activeAcquirer, card_type.id) })
                                                        </span>
                                                    </div>
                                                    <div className="card-type-content">
                                                        <div className="text-center">
                                                            <Button onClick={ () => { addRate(activePs, activeAcquirer, card_type.id); }} color="info" size="sm">
                                                                <Icon path={ mdiPlusCircleOutline } color="white" size="22" />
                                                                { t('rates.addRate') }
                                                            </Button>
                                                        </div>
                                                        { rates && rates.filter(rate => (rate.payment_system_id === activePs && rate.acquirer_id === activeAcquirer && rate.card_type_id === card_type.id)).map(rate => (
                                                            <>
                                                                { activeRate === rate.id && (
                                                                    <RateForm initialValues={ rate } onSubmit={ handleRateEdition } closeForm={ setActiveRate }/>
                                                                )}
                                                                {(!activeRate || activeRate !== rate.id) && (
                                                                    <RateDetails rate={ rate } editRate={ showRateForm } removeRate={ removeRate } />
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <hr/>
                                    <div className="unused-card-types">
                                        <h4>{ t('rates.inactive_card_types') }</h4>
                                        { card_types && card_types.filter(card_type => card_type.name !== 'none' && nbRates(activePs, activeAcquirer, card_type.id) === 0).map(card_type => (
                                            <>
                                                <div className={ `card-type-wrapper unused ${activeCardType === card_type.id ? 'active' : '' } `} onClick={ () => { showCardTypeContent(card_type.id); }} size='sm'>
                                                    <div className="card-type-title">
                                                        <span className="icon">
                                                            <Icon path={ mdiCreditCardOutline } color="white" size="22" />
                                                        </span>
                                                        <span className="text">
                                                            { card_type.name }{' '}
                                                            ({ nbRates(activePs, activeAcquirer, card_type.id) })
                                                        </span>
                                                    </div>
                                                    <div className="card-type-content">
                                                        <div className="text-center">
                                                            <Button onClick={ () => { addRate(activePs, activeAcquirer, card_type.id); }} color="info" size="sm">
                                                                <Icon path={ mdiPlusCircleOutline } color="white" size="22" />
                                                                { t('rates.addRate') }
                                                            </Button>
                                                        </div>
                                                        { rates && rates.filter(rate => (rate.payment_system_id === activePs && rate.acquirer_id === activeAcquirer && rate.card_type_id === card_type.id)).map(rate => (
                                                            <>
                                                                { activeRate === rate.id && (
                                                                    <RateForm initialValues={ rate } onSubmit={ handleRateEdition } closeForm={ setActiveRate }/>
                                                                )}
                                                                {(!activeRate || activeRate !== rate.id) && (
                                                                    <RateDetails rate={ rate } editRate={ showRateForm } removeRate={ removeRate } />
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </>
                            ))}
                        </>
                    ))}
                </CardBody>
            </Card>
        </div>
    );
}

export default AccountRatesForm;
