import BaseModel from './BaseModel';

/** Class representing a report. */
class Report extends BaseModel { }

Report.format_amount = (amount) => {
    return new Intl.NumberFormat('fr-CH', {
        style: 'decimal',
    }).format(amount)
}

Report.format_money = (amount, currency) => {
    return new Intl.NumberFormat('fr-CH', {
        currency,
        style: 'currency',
    }).format(amount)
}

export default Report;
