import RestClient from './RestClient';
import { Rate } from '../../models';

class Rates extends RestClient {
    constructor() {
        super({
            model: Rate,
            entryPoint: 'rate',
            resource:   'rate',
            resources:  'rates'
        });
    }

    copyRates(sourceId, targetId) {
        return this.POST('/rate/copy', { source_id: sourceId, target_id: targetId })
            .then(response => response.json())
            .then(json => {
                if (json.rates) {
                    json.rates = json.rates.map(rate => new Rate(rate));
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const RatesApi = new Rates();

export default RatesApi;
