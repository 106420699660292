import React from 'react';
import { Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';

import './Loader.css';

const Loader = () => {
    return (
        <div className="Loader">
            <Row className="justify-content-center">
                <Col className="align-self-center spinner-wrapper">
                    <Icon path={ mdiCircleSlice1 } color="white" spin={1} />
                </Col>
            </Row>
        </div>
    );
}

export default Loader;
