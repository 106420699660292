import React from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import { Button } from 'reactstrap';

import './StyledTable.css';

const StyledTable = (props) => {
    const { t } = useTranslation();

    const { data, columns, defaultSorted, hideSearch, hideExport, ...otherProps } = props;

    const sizePerPage    = props.sizePerPage || 50;
    const hidePagination =
        props.hidePagination ||
        ((data || []).length <= sizePerPage);

    const { SearchBar } = Search;

    const CsvExportButton = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button color="info" onClick={ handleClick }>
                <Icon path={mdiDownload} size={1} color="white" />
                Export CSV
            </Button>
        );
    };

    const pagination = hidePagination ?
        null :
        paginationFactory({
            sizePerPage: sizePerPage,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }]
        });

    return (
        <div className="StyledTable">
        {data && (
            <ToolkitProvider
                bootstrap4
                keyField="id"
                data={ data }
                columns={ columns }
                search
                exportCSV={{ fileName: 'export.csv' }}
                defaultSorted={ defaultSorted }
            >
                {
                    props => (
                        <div>
                            <div className="top-actions text-right">
                                {!hideSearch &&
                                    <SearchBar placeholder={t('common.search')} { ...props.searchProps } />
                                }
                                {!hideExport &&
                                    <CsvExportButton { ...props.csvProps } />
                                }
                            </div>
                            <BootstrapTable
                                pagination={ pagination }
                                filter={ filterFactory() }
                                { ...otherProps }
                                { ...props.baseProps }
                            />
                        </div>
                    )
                }
            </ToolkitProvider>
        )}
        </div>
    );
}

export default StyledTable;
