import { CardTypes } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    startCreateReducer, createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    startCreateAction, createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(CardTypes);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        startCreateReducer, createReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
    ]);
}

/* Export CRUD actions */
export const startCreateCardType = startCreateAction;
export const createCardType      = createAction;
export const loadCardType        = getAction;
export const loadCardTypes       = listAction;
export const updateCardType      = updateAction;
export const deleteCardType      = deleteAction;
