import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import BatchsList from './BatchsList/BatchsList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Batchs = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Batchs">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><BatchsList /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default Batchs;
