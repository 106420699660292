import { Devices } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    startCreateReducer, createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    startCreateAction, createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(Devices);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        startCreateReducer, createReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
    ]);
}

/* Export CRUD actions */
export const startCreateDevice = startCreateAction;
export const createDevice      = createAction;
export const loadDevice        = getAction;
export const loadDevices       = listAction;
export const updateDevice      = updateAction;
export const deleteDevice      = deleteAction;
