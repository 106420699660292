import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiBriefcasePlus, mdiArrowLeftCircle } from '@mdi/js';
import { useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PaymentSystemForm from '../PaymentSystemForm/PaymentSystemForm';
import { createPaymentSystem, startCreatePaymentSystem } from '../../../redux/modules/payment_systems';

const PaymentSystemCreate = () => {
    const dispatch                         = useDispatch();
    const { path }                         = useRouteMatch();
    const { t }                            = useTranslation();
    const { payment_system, successfullyCreated } = useSelector(state => state.payment_systems);

    useEffect(() => {
        dispatch(startCreatePaymentSystem());
    }, [dispatch]);

    if (successfullyCreated && payment_system) {
        return <Redirect to={ `edit/${payment_system.id}` } />
    }

    const actions = <Link to={ `${path.replace('new', '')}` }>
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handlePaymentSystemCreation = (values) => {
        dispatch(createPaymentSystem(values));
    };

    return (
        <div className="PaymentSystemCreate">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('payment_systems.add_title') }
                        icon={ <Icon path={mdiBriefcasePlus} color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <PaymentSystemForm onSubmit={ handlePaymentSystemCreation } isCreation={ true } />
                </Col>
            </Row>
        </div>
    );
}

export default PaymentSystemCreate;
