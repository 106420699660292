import { PaymentSystems } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    startCreateReducer, createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    startCreateAction, createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(PaymentSystems);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        startCreateReducer, createReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
    ]);
}

/* Export CRUD actions */
export const startCreatePaymentSystem  = startCreateAction;
export const createPaymentSystem       = createAction;
export const loadPaymentSystem         = getAction;
export const loadPaymentSystems        = listAction;
export const updatePaymentSystem       = updateAction;
export const deletePaymentSystem       = deleteAction;
