import BaseModel from './BaseModel';

/** Class representing an user. */
class User extends BaseModel {
    userRole       = () => User.ROLE_USER;
    adminRole      = () => User.ROLE_ADMIN;
    superAdminRole = () => User.ROLE_SUPER_ADMIN;

    isUser       = () => this.isAdmin() || this.role === User.ROLE_USER;
    isAdmin      = () => this.isSuperAdmin() || this.role === User.ROLE_ADMIN;
    isSuperAdmin = () => this.role === User.ROLE_SUPER_ADMIN;

    // returns true if user role allows him to have the given role
    hasRole = (role) => {
        return {
            [User.ROLE_SUPER_ADMIN]: this.isSuperAdmin(),
            [User.ROLE_ADMIN]: this.isAdmin(),
            [User.ROLE_USER]: this.isUser()
        }[role];
    }

    can(context, action) {
        return (
            context in this.abilities &&
            action in this.abilities[context]
        );
    }
}

/**
 * Get the fields to get server side when we
 * ask for a users listing
 */
User.getListingFields = () => [
    'id',
    'fullname',
    'role',
    'email'
];

User.GENDER_DEFAULT = '?';
User.GENDER_FEMALE  = 'F';
User.GENDER_MALE    = 'M';

User.ROLE_SUPER_ADMIN = 'super_admin';
User.ROLE_ADMIN       = 'admin';
User.ROLE_USER        = 'user';

User.genders = () => {
    return [
        User.GENDER_DEFAULT,
        User.GENDER_FEMALE,
        User.GENDER_MALE,
    ];
};

User.roles = () => {
    return [
        User.ROLE_SUPER_ADMIN,
        User.ROLE_ADMIN,
        User.ROLE_USER,
    ];
};

export default User;
