import BaseModel from './BaseModel';

/** Class representing an user. */
class CardType extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a card types listing
 */
CardType.getListingFields = () => [
    'id',
    'name'
];

export default CardType;
