import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, Row, Col } from 'reactstrap';
import { mdiPencil, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';

import './RateDetails.css';

let RateDetails = ({ rate, editRate, removeRate }) => {
    const { t } = useTranslation();
    const { isLoading } = useSelector(state => state.rates);

    if (!rate)
        return null;

    return (
        <div className="RateDetails">
            <Row>
                <Col>
                    <div className="rate-details-header text-right">
                        <ButtonGroup>
                            <Button onClick={() => { removeRate(rate.id) }} color="danger" disabled={ isLoading } size="sm">
                                <Icon path={ mdiDelete } size={ 0.75 } color="white" />
                                &nbsp;{t("rates.delete")}
                            </Button>
                            <Button onClick={() => { editRate(rate.id) }} color="info" disabled={ isLoading } size="sm">
                                <Icon path={ mdiPencil } size={ 0.75 } color="white" />
                                &nbsp;{t("rates.edit")}
                            </Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="start_at">
                        <span className="label">{ t('rates.start_at') }</span>
                        <span className="value">{ moment(rate.start_at).format('LL') }</span>
                    </div>
                </Col>
            </Row>
            <Row>
                {['payment_system_fees', 'acquirer_fees'].map(feesType => (
                    <Col key={ feesType }>
                        <Row>
                            <Col>
                                <div className="label">{ t(`rates.${ feesType }`) }</div>
                            </Col>
                        </Row>
                        { rate[feesType].map((r, i) => (
                        <Row key={i}>
                            <Col>
                                <div>
                                    <span className="label">{ t('rates.type') }</span>
                                    <span className="value">{ t(`rates.types.${ r.type }`) }</span>
                                </div>
                                <div>
                                    <span className="label">{ t('rates.rate') }</span>
                                    <span className="value">{ r.rate }</span>
                                </div>
                            </Col>
                        </Row>
                        ))}
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default RateDetails;
