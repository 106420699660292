import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserCreate from './UserCreate/UserCreate';
import UserEdit from './UserEdit/UserEdit';
import UsersList from './UsersList/UsersList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Users = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Users">
            <Switch>

                <>
                    <Role has={ User.ROLE_ADMIN }>
                        <Route exact path={path}><UsersList /></Route>
                        <Route exact path={`${path}/new`}><UserCreate /></Route>
                    </Role>

                    <Route exact path={`${path}/edit/:userId`}><UserEdit /></Route>
                </>

            </Switch>
        </div>
    );
}

export default Users;
