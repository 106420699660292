import { Accounts } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    startCreateReducer, createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    startCreateAction, createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(Accounts);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        startCreateReducer, createReducer,
        getReducer, listReducer,
        updateReducer, deleteReducer,
    ]);
}

/* Export CRUD actions */
export const startCreateAccount = startCreateAction;
export const createAccount      = createAction;
export const loadAccount        = getAction;
export const loadAccounts       = listAction;
export const updateAccount      = updateAction;
export const deleteAccount      = deleteAction;
