import { Transactions } from '../../../services';

const GETTING_BALANCE         = `pixoo/transactions/GETTING_BALANCE`;
const GETTING_BALANCE_SUCCESS = `pixoo/transactions/GETTING_BALANCE_SUCCESS`;
const GETTING_BALANCE_FAILURE = `pixoo/transactions/GETTING_BALANCE_FAILURE`;

/* reducer */
const balanceReducer = (state, action) => {
    switch (action.type) {
            case GETTING_BALANCE:
                return {
                    ...state,
                    isGettingBalance: true,
                    hasGettingBalanceError: false
                };
            case GETTING_BALANCE_SUCCESS:
                return {
                    ...state,
                    isGettingBalance: false,
                    hasGettingBalanceError: false,
                    balance: action.balance
                };
            case GETTING_BALANCE_FAILURE:
                return {
                    ...state,
                    isGettingBalance: false,
                    hasGettingBalanceError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function gettingBalance() { return { type: GETTING_BALANCE } }
function gettingBalanceSuccess(balance) { return { type: GETTING_BALANCE_SUCCESS, balance: balance } }
function gettingBalanceFailure(err) { return { type: GETTING_BALANCE_FAILURE, error: err } }
const getBalanceAction = (filters) => {
    return (dispatch) => {
        dispatch(gettingBalance());
        Transactions.getBalance(filters)
            .then(data => {
                const { balance } = data;
                dispatch(gettingBalanceSuccess(balance));
            })
            .catch(err => {
                dispatch(gettingBalanceFailure(err))
            });
    }
}

/* Export reducer */
export default { balanceReducer,  getBalanceAction };
