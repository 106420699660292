import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './locales/i18n';
import 'semantic-ui-css/semantic.min.css'
import './index.scss';
import store from './redux/configureStore';
import App from './containers/App/App';
import 'semantic-ui-css/semantic.min.css'

//const store = configureStore();

const renderApp = () => render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./containers/App/App', renderApp);
}

renderApp();

serviceWorker.register();
