import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StyledTable from '../StyledTable/StyledTable';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'reactstrap';
import { Report } from '../../models';
import FixBalancesButton from '../FixBalancesButton/FixBalancesButton';
import { mdiCashMultiple, mdiEyeOutline } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import './FinancialHistory.css';

const FinancialHistory = ({history, canFixBalances, ...otherProps}) => {
    const { t }    = useTranslation();
    const { user } = useSelector(state => state.auth);

    const details_link = (id) => (
        <Link to={'/payments/edit/' + id}>
            <Button color="info" size="sm">
                <Icon path={ mdiEyeOutline } color="white" size={1} />
            </Button>
        </Link>
    );
    const doneColumns = [{
        dataField: 'payment_date',
        text: t('payments.payment_date'),
        formatter: (date) => moment(date).format('LL')
    }, {
        dataField: 'balance_before',
        text: t('payments.balance_before'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }, {
        dataField: 'total_transaction',
        text: t('payments.total_transaction'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }, {
        dataField: 'amount',
        text: t('financialHistory.payment'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }, {
        dataField: 'balance_after',
        text: t('payments.balance_after'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }];

    const pendingColumns = [{
        dataField: 'payment_date',
        text: t('payments.payment_date'),
        formatter: (date) => moment(date).format('LL')
    }, {
        dataField: 'balance_before',
        text: t('payments.balance_before'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }, {
        dataField: 'total_transaction',
        text: t('payments.total_transaction'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }, {
        dataField: 'amount',
        text: t('financialHistory.payment'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }, {
        dataField: 'balance_after',
        text: t('payments.balance_after'),
        formatter: (amount) => Report.format_money(amount, history.currency)
    }];

    if (user.isSuperAdmin()) {
        doneColumns.push({
            dataField: 'id',
            text: '',
            formatter: details_link
        });
        pendingColumns.push({
            dataField: 'id',
            text: '',
            formatter: details_link
        });
    }
    const defaultSorted = [{
        dataField: 'created_at',
        order: 'asc'
    }];

    const isDone          = (p) => (p.completed && moment(p.payment_date).isBefore(moment()));
    const donePayments    = (history.payments || []).filter(p => isDone(p)).reverse();
    const pendingPayments = (history.payments || []).filter(p => !isDone(p));

    const paymentsList = donePayments.length > 0 ?
        <StyledTable
            data={ donePayments }
            columns={ doneColumns }
            defaultSorted={ defaultSorted }
            sizePerPage={ 10 }
            hideSearch
            hideExport
            { ...otherProps }
        /> :
        <h3 className="text-center">{ t('financialHistory.no_payments_yet') }</h3>;

    return (
        <div className="FinancialHistory">
            <h3 className="text-left">
                { canFixBalances && (
                <div className="float-right">
                    <FixBalancesButton accountId={ history.account_id } />
                </div>
                )}
                <Badge color="outline">
                    { t('financialHistory.ongoing') }: {' '}
                    { Report.format_money(history.ongoing, history.currency) }
                </Badge><br/>
                <Badge color="outline">
                    { t('financialHistory.balance') }:{' '}
                    { Report.format_money(history.balance, history.currency) }
                </Badge>
            </h3>
            {pendingPayments.length > 0 &&
                <>
                    <hr/>
                    <h4><Icon path={ mdiCashMultiple } color="white" size={1} /> { t('financialHistory.payment_pending') }</h4>
                    <StyledTable
                        data={ pendingPayments }
                        columns={ pendingColumns }
                        defaultSorted={ defaultSorted }
                        hideSearch
                        hideExport
                        { ...otherProps }
                    />
                    <hr/>
                </>
            }
            <hr/>
            <h4><Icon path={ mdiCashMultiple } color="white" size={1} /> { t('financialHistory.payment_completed') }</h4>
            { paymentsList }
        </div>
    );
}

export default FinancialHistory;
