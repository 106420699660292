import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';

let PaymentSystemForm = ({ isCreation, handleSubmit, pristine, submitting }) => {
    const { t }         = useTranslation();
    const { isLoading } = useSelector(state => state.payment_systems);

    return (
        <Form className="form" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('payment_systems.add_title') : t('payment_systems.edit_title') }</CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="name">{ t('payment_systems.name') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('payment_systems.name') } name="name" id="name" required />
                    </FormGroup>
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading }>
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

PaymentSystemForm = reduxForm({
    form: 'payment_systemForm',
    enableReinitialize: true,
})(PaymentSystemForm)

PaymentSystemForm = connect(
    state => ({
        initialValues: state.payment_systems.payment_system
    })
)(PaymentSystemForm)

export default PaymentSystemForm;
