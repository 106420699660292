import BaseModel from './BaseModel';
import Account from './Account';
import Acquirer from './Acquirer';
import CardType from './CardType';
import Device from './Device';
import PaymentSystem from './PaymentSystem';

import {
    mdiCash,
    mdiCashRefund,
    mdiCheck,
    mdiTimerSand,
    mdiCloseCircle,
    mdiAlertCircle
} from '@mdi/js';

/** Class representing an user. */
class Transaction extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.account)
            this.account = new Account(this.account);
        if (this.acquirer)
            this.acquirer = new Acquirer(this.acquirer);
        if (this.card_type)
            this.card_type = new CardType(this.card_type);
        if (this.device)
            this.device = new Device(this.device);
        if (this.payment_system)
            this.payment_system = new PaymentSystem(this.payment_system);
        if (this.linked_transactions)
            this.linked_transactions = this.linked_transactions.map(transaction => new Transaction(transaction));
    }

    type_icon() {
        switch (this.type) {
            case Transaction.TYPE_PAYMENT:
                return mdiCash;
            case Transaction.TYPE_REFUND:
            case Transaction.TYPE_CANCEL:
                return mdiCashRefund;
            default:
                return mdiAlertCircle;
        }
    }

    status_icon() {
        switch (this.status) {
            case Transaction.STATUS_OK:
                return mdiCheck;
            case Transaction.STATUS_PENDING:
                return mdiTimerSand;
            case Transaction.STATUS_CANCELLED:
                return mdiCloseCircle;
            case Transaction.STATUS_ERROR:
            default:
                return mdiAlertCircle;
        }
    }
}

/**
 * Get the fields to get server side when we
 * ask for a transactions listing
 */
Transaction.getListingFields = () => [
    'id',
    'currency',
    'gross_amount',
    'effective_amount',
    'payment_system_fees',
    'acquirer_fees',
    'netox_payment_system_fees',
    'netox_acquirer_fees',
    'netox_profit',
    'net_amount',
    'type',
    'status',
    'card_number',
    'ref',
    'order_id',
    'order_date',
    'transaction_date',
    'buyer',
    'description',
    'completed',
    'payment_system.name',
    'acquirer.name',
    'account.name',
    'card_type.name',
    'device.name',
    'device.identifier',
    'device.can_refund',
    'payment_id',
    'payment_date',
    'payment.value_date',
    'payment.iban'
];

Transaction.TYPE_PAYMENT = 'payment';
Transaction.TYPE_REFUND  = 'refund';
Transaction.TYPE_CANCEL  = 'cancel';

Transaction.STATUS_OK        = 'ok';
Transaction.STATUS_PENDING   = 'pending';
Transaction.STATUS_CANCELLED = 'cancelled';
Transaction.STATUS_ERROR     = 'error';

Transaction.PAYMENT_STATUS_PAID    = 'paid';
Transaction.PAYMENT_STATUS_PENDING = 'pending_payment';
Transaction.PAYMENT_STATUS_UNPAID  = 'unpaid';

Transaction.types = () => {
    return [
        Transaction.TYPE_PAYMENT,
        Transaction.TYPE_REFUND,
        Transaction.TYPE_CANCEL
    ];
};

Transaction.status = () => {
    return [
        Transaction.STATUS_OK,
        Transaction.STATUS_PENDING,
        Transaction.STATUS_CANCELLED,
        Transaction.STATUS_ERROR
    ];
};

Transaction.payment_status = () => {
    return [
        Transaction.PAYMENT_STATUS_PAID,
        Transaction.PAYMENT_STATUS_PENDING,
        Transaction.PAYMENT_STATUS_UNPAID
    ];
};

Transaction.currencies = () => {
    return [
        'CHF',
        'EUR',
        'USD'
    ];
};

export default Transaction;
