import React from 'react';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './UserAccoutsField.css';

const UserAccoutsField = ({ name, accounts, values }) => {
    if (!accounts)
        return null;

    const options = accounts.map(account => {
        return {
            key: account.id,
            text: account.name,
            value: account.id
        };
    });

    return <MultiSelectField name={ name } options={ options } values= { values } />
}

export default UserAccoutsField;
