import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiCreditCardOutline, mdiCreditCardPlusOutline, mdiPencil } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { loadCardTypes } from '../../../redux/modules/card_types';
import StyledTable from '../../../components/StyledTable/StyledTable';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import PageTitle from '../../../components/PageTitle/PageTitle';

const CardTypesList = () => {
    const dispatch                  = useDispatch();
    const { path }                  = useRouteMatch();
    const { t }                     = useTranslation();
    const { isLoading, card_types } = useSelector(state => state.card_types);



    useEffect(() => {
        dispatch(loadCardTypes());
    }, [dispatch]);

    if (!card_types || isLoading) {
        return <FullPageLoader />
    }

    const actionFormatter = (id, cardType) =>
        <Button tag={ Link } to={ `${path}/edit/${id}` } color="success"><Icon path={ mdiPencil } size={ 1 } color="white" /></Button>;

    const columns = [{
        dataField: 'name',
        text: t('card_types.name'),
        sort: true
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }];

    const defaultSorted = [{
        dataField: 'fullname',
        order: 'desc'
    }];

    const actions = <Link to={`${path}/new`}>
        <Button color="info">
            <Icon path={ mdiCreditCardPlusOutline } size={ 1 } color="white" /> { t('card_types.add') }
        </Button>
    </Link>;

    return (
        <div className="CardTypesList">
            <PageTitle
                title={ t('card_types.list_title') }
                icon={ <Icon path={ mdiCreditCardOutline } color="white" /> }
                actions={ actions }
            />
            <StyledTable
                bootstrap4
                keyField="id"
                data={ card_types }
                columns={ columns }
                defaultSorted={ defaultSorted }
                sizePerPage= { 25 }
            />
        </div>
    );
}

export default CardTypesList;
