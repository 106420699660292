import BaseModel from './BaseModel';

/** Class representing an user. */
class PaymentSystem extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a payment systems listing
 */
PaymentSystem.getListingFields = () => [
    'id',
    'name'
];

export default PaymentSystem;
