import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiCashPlus, mdiCashMultiple, mdiPencil } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { loadPayments } from '../../../redux/modules/payments';
import StyledTable from '../../../components/StyledTable/StyledTable';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import moment from 'moment';

const PaymentsList = () => {
    const dispatch                = useDispatch();
    const { path }                = useRouteMatch();
    const { t }                   = useTranslation();
    const { isLoading, payments } = useSelector(state => state.payments);

    useEffect(() => {
        dispatch(loadPayments());
    }, [dispatch]);

    if (!payments || isLoading) {
        return <FullPageLoader />
    }
    
    const actionFormatter = (id, payment) =>
        <Button tag={ Link } to={ `${path}/edit/${id}` } color="success"><Icon path={ mdiPencil } size={ 1 } color="white" /></Button>;

    const cellFormatter = (text, smallText, capitalizeSmallText) =>
        <>
            <div>
                { text }
            </div>
            {smallText &&
                <small className={capitalizeSmallText ? 'capitalize': ''}>{ smallText }</small>
            }
        </>

    const columns = [{
        dataField: 'payment_date',
        text: t('payments.payment_date'),
        sort: true,
        classes: (type, row) => 'with-left-icon',
        formatter: (payment_date, value_date, row) => cellFormatter(
        moment(payment_date).format('LL'),
        moment(value_date).format('LL')
        ),
    }, {
        dataField: 'account.name',
        text: t('payments.account_id'),
        sort: true,
        formatter: (account_name, payments,  row) => cellFormatter(
            account_name,
            payments.iban,
            false
        ),
    }, {
        dataField: 'currency',
        text: t('payments.currency'),
        sort: true
    }, {
        dataField: 'amount',
        text: t('payments.amount'),
        sort: true
    }, {
        dataField: 'completed',
        text: t('payments.completed'),
        sort: true,
        formatter: (completed, row) => completed ? t('payments.ok') : t('payments.not_ok') 
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }, {
        dataField: 'iban',
        text: t('payments.iban'),
        hidden: true
    }, {
        dataField: 'balance_before',
        text: t('payments.balance_before'),
        hidden: true
    }, {
        dataField: 'value_date',
        text: t('payments.value_date'),
        hidden: true
    }, {
        dataField: 'total_transaction',
        text: t('payments.total_transaction'),
        hidden: true
    }, {
        dataField: 'balance_after',
        text: t('payments.balance_after'),
        hidden: true
    }];

    const defaultSorted = [{
        dataField: 'fullname',
        order: 'desc'
    }];

    const actions = <Link to={`${path}/new`}>
        <Button color="info">
            <Icon path={ mdiCashPlus } size={ 1 } color="white" /> { t('payments.add') }
        </Button>
    </Link>;

    return (
        <div className="PaymentsList">
            <PageTitle
                title={ t('payments.list_title') }
                icon={ <Icon path={ mdiCashMultiple } color="white" /> }
                actions={ actions }
            />
            <StyledTable
                bootstrap4
                keyField="id"
                data={ payments }
                columns={ columns }
                defaultSorted={ defaultSorted }
                sizePerPage= { 5 }
            />
        </div>
    );
}

export default PaymentsList;
