import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiCreditCardOutline, mdiArrowLeftCircle } from '@mdi/js';
import { useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import CardTypeForm from '../CardTypeForm/CardTypeForm';
import { createCardType, startCreateCardType } from '../../../redux/modules/card_types';

const CardTypeCreate = () => {
    const dispatch                                    = useDispatch();
    const { path }                                    = useRouteMatch();
    const { t }                                       = useTranslation();
    const { card_type, card_typeSuccessfullyCreated } = useSelector(state => state.card_types);

    useEffect(() => {
        dispatch(startCreateCardType());
    }, [dispatch]);

    if (card_typeSuccessfullyCreated && card_type) {
        return <Redirect to={ `edit/${card_type.id}` } />
    }

    const actions = <Link to={ `${path.replace('new', '')}` }>
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleCardTypeCreation = (values) => {
        dispatch(createCardType(values));
    };

    return (
        <div className="CardTypeCreate">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('cardTypes.add_title') }
                        icon={ <Icon path={mdiCreditCardOutline} color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <CardTypeForm onSubmit={ handleCardTypeCreation } isCreation={ true } />
                </Col>
            </Row>
        </div>
    );
}

export default CardTypeCreate;
