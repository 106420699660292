import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Widget from '../Widget/Widget';
import { Row, Col } from 'reactstrap';

import './TransactionsResume.css';

const TransactionsResume = ({ transactions, askPaymentButton }) => {
    const { t }                      = useTranslation();
    const { user }                   = useSelector(state => state.auth);
    const  [isVisible, setIsvisible] = useState(true);

    if (!transactions || !transactions.length)
        return "";

    const nb_transactions = transactions.length;
    const effective_total = transactions.filter(t => t.completed).reduce(
        (memo, t) => memo + t.effective_amount,
    0.00).toFixed(2);
    const net_total = transactions.filter(t => t.completed).reduce(
        (memo, t) => memo + t.net_amount,
    0.00).toFixed(2);
    //const fees_total = (effective_total - net_total).toFixed(2);
    const fees_total = transactions.filter(t => t.completed).reduce(
        (memo, t) => memo + t.payment_system_fees + t.acquirer_fees,
    0.00).toFixed(2);
    const profit_total = transactions.reduce(
        (memo, t) => memo + t.netox_profit,
    0.00).toFixed(2);
    const due_total = transactions.filter(t => !t.payment_id).reduce(
        (memo, t) => memo + t.net_amount,
    0.00).toFixed(2);

    const printClass = user.isSuperAdmin() ? "sixColPrint colPrint" : "fiveColPrint colPrint";

    const lg_size = user.isSuperAdmin() ? 3 : 3;

    return (
        <div className="TransactionsResume">
            <Row>
                <Col sm={6} lg={lg_size} className={`${printClass}`}>
                    <Widget
                        title={ t('transactions.resume.nb_transactions') }
                        value={nb_transactions}
                    />
                </Col>
                <Col sm={6} lg={lg_size} className={`${printClass}`}>
                    <Widget
                        title={ t('transactions.resume.gross_total') }
                        value={effective_total + ' ' + transactions[0].currency}
                    />
                </Col>
                <Col sm={6} lg={lg_size} className={`${printClass}`}>
                    <Widget
                        title={ t('transactions.resume.fees_total') }
                        value={fees_total + ' ' + transactions[0].currency}
                    />
                </Col>
                <Col sm={6} lg={lg_size} className={`${printClass}`}>
                    <Widget
                        title={ t('transactions.resume.net_total') }
                        value={net_total + ' ' + transactions[0].currency}
                    />
                </Col>
                <Col className={`${printClass}`}>
                    <Widget
                        title={ t('transactions.resume.due_total') }
                        value={due_total + ' ' + transactions[0].currency}
                        action={ due_total > 0 ? askPaymentButton : null }
                    />
                </Col>
                { user.isSuperAdmin() && isVisible &&(
                <Col sm={6} lg={lg_size} className={`${printClass}`} onDoubleClick={() => setIsvisible(false)} >
                    <Widget
                        title={ t('transactions.resume.profit_total') }
                        value={profit_total + ' ' + transactions[0].currency}
                    />
                </Col>
                )}
            </Row>
        </div>
    );
}

export default TransactionsResume;
