import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import defaultTheme from '../theme';
import { merge } from 'lodash';

const LineChart = (props) => {
    const {
        data,
        curve,
        enableArea,
        enableGridX,
        enableGridY,
        theme,
        tooltip,
        scheme,
        tickRotation,
    } = props;

    const legends = [{
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemTextColor: '#fff',
    }];

    const chartTheme = merge(defaultTheme, theme);

    return (
        <ResponsiveLine
            data={ data }
            curve={ curve }
            enableArea={ enableArea }
            enableGridX={ enableGridX }
            enableGridY={ enableGridY }
            axisBottom={{
                tickRotation: tickRotation || 0
            }}
            margin={{
                top: 50,
                right: 130,
                bottom: 50,
                left: 60
            }}
            colors={{ scheme: scheme }}
            theme={ chartTheme }
            legends={ legends }
            useMesh={ true }
            enableCrosshair={ false }
            tooltip={ tooltip }
        />
    );
}

LineChart.defaultProps = {
    curve: 'linear',
    enableArea: false,
    enableGridX: false,
    enableGridY: true,
    theme: {},
    tooltip: undefined,
    scheme: 'red_yellow_green',
};

LineChart.propTypes = {
    curve: PropTypes.string,
    enableArea: PropTypes.bool,
    enableGridX: PropTypes.bool,
    enableGridY: PropTypes.bool,
    theme: PropTypes.object,
    tooltip: PropTypes.func,
    scheme: PropTypes.string,
};

export default LineChart;
