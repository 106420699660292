import { Rates } from '../../../services';

const COPYING_RATES         = `pixoo/rates/COPYING_RATES`;
const COPYING_RATES_SUCCESS = `pixoo/rates/COPYING_RATES_SUCCESS`;
const COPYING_RATES_FAILURE = `pixoo/rates/COPYING_RATES_FAILURE`;

/* reducer */
const copyRatesReducer = (state, action) => {
    switch (action.type) {
            case COPYING_RATES:
                return {
                    ...state,
                    isCopyingRates: true,
                    hasCopyingRatesError: false
                };
            case COPYING_RATES_SUCCESS:
                return {
                    ...state,
                    isCopyingRates: false,
                    hasCopyingRatesError: false,
                };
            case COPYING_RATES_FAILURE:
                return {
                    ...state,
                    isCopyingRates: false,
                    hasCopyingRatesError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function copyingRates() { return { type: COPYING_RATES } }
function copyingRatesSuccess() { return { type: COPYING_RATES_SUCCESS } }
function copyingRatesFailure(err) { return { type: COPYING_RATES_FAILURE, error: err } }
const copyRatesAction = (sourceId, targetId) => {
    return (dispatch) => {
        dispatch(copyingRates());
        return Rates.copyRates(sourceId, targetId)
            .then(data => {
                dispatch(copyingRatesSuccess());
            })
            .catch(err => {
                dispatch(copyingRatesFailure(err))
            });
    }
}

/* Export reducer */
export default { copyRatesReducer,  copyRatesAction };
