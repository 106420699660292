import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../../models';
import { loadTransaction, refund } from '../../redux/modules/transactions';
import Loader from '../../components/Loader/Loader';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import TransactionsListing from '../../components/TransactionsListing/TransactionsListing';
import {
    Container,
    Row, Col, Button, Modal, Badge,
    ModalHeader, ModalBody, ModalFooter,
    InputGroup, InputGroupAddon, Input,
    InputGroupText
} from 'reactstrap';
import Icon from '@mdi/react';
import {
    mdiCreditCardRefundOutline,
    mdiClose,
    mdiTimerSand
} from '@mdi/js';
import moment from 'moment';

import './TransactionModal.css';

const TransactionModal = ({transaction_id, show, toggle, onClose}) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [ refundAmount, setRefundAmound ]         = useState(0);
    const [ refundableAmount, setRefundableAmound ] = useState(0);

    const {
        transaction,
        isLoadingOne,
        isRefunding,
        hasRefundingError,
        refundingErrorMessage,
    } = useSelector((state) => state.transactions);

    const request = transaction?.request;

    const do_refund = () => {
        if (transaction && refundAmount > 0)
            dispatch(refund(transaction, refundAmount));
    };

    useEffect(() => {
        if (!transaction_id)
            return;
        dispatch(loadTransaction(transaction_id));
    }, [dispatch, transaction_id]);

    useEffect(() => {
        if (!transaction)
            return setRefundableAmound(0);

        let refundable =
            transaction.effective_amount +
            transaction.linked_transactions.reduce(
                (total, linked) => total + (linked.completed ? linked.effective_amount : 0),
                0
            );

        // do not allow refunds on uncompleted transactions
        if (!transaction.completed)
            refundable = 0;

        // do not allow refunds on refunds or cancel transactions
        if (transaction.type !== Transaction.TYPE_PAYMENT)
            refundable = 0;

        // do not allow refunds on non ok transactions
        if (transaction.status !== Transaction.STATUS_OK)
            refundable = 0;

        // allow refunds only if can_refund (on pixoo) is true
        if (!transaction.device || !transaction.device.can_refund)
            refundable = 0;

        setRefundAmound(refundable);
        setRefundableAmound(refundable);
    }, [transaction]);

    const isProcessing =
        isRefunding || isLoadingOne ||
        !transaction_id || !transaction ||
        transaction_id !== transaction.id;

    return (
        <Modal backdrop={ true } size={'lg'} isOpen={transaction && show} className="TransactionModal" toggle={ toggle }>
            { isProcessing && <Loader /> }

            { !isProcessing && (
            <>
                <ModalHeader toggle={ onClose }>
                    { `${t('transactions.ref')}: ${transaction.ref}`}
                    {!transaction.completed && (
                        <span className="warning-message text-right">
                            <Icon path={mdiTimerSand} size={2} color="#A7191E" className="left-icon" />
                            { t('transactions.uncompleted') }
                        </span>
                    )}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <h3 className="effective_amount text-center">
                                { transaction.effective_amount.toFixed(2) }
                                &nbsp;
                                { transaction.currency }
                            </h3>
                            <h3 className="badges text-center">
                                <Badge className={`${transaction.status} btn-block`}>
                                    <Icon path={transaction.type_icon(transaction.type)} size={1} color="white" className="left-icon" />
                                    {
                                        `${ ((request && request.env === "test" && t('transactions.env.' + request.env )) || '')}
                                         ${t('transactions.types.' + transaction.type)} ${t('transactions.statuses.' + transaction.status).toLowerCase()}`
                                    }
                                </Badge>
                            </h3>
                            <hr/>
                            <Row>
                                <Col>
                                    <div className="buyer text-center">
                                        <strong>{ request?.fullname || transaction.buyer }</strong>
                                    </div>
                                    <div className="order_id text-center">
                                        <small>{ transaction.order_id }</small>
                                    </div>
                                    <div className="description text-center">
                                        { transaction.description }
                                    </div>
                                </Col>
                                { request && (
                                <Col>
                                    <div className="email text-center">
                                        { request?.email }
                                    </div>
                                    <div className="address text-center">
                                        { request?.address }
                                    </div>
                                    <div className="zip text-center">
                                        { request?.zip + " " + request?.city }
                                    </div>
                                    <div className="country text-center">
                                        { request?.country }
                                    </div>
                                    <div className="description text-center">
                                        { request?.description }
                                    </div>
                                </Col>
                                )}
                            </Row>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="transaction_date text-center">
                                <label>
                                    { t('transactions.transaction_date') }
                                </label>
                                { moment(transaction.transaction_date).format('L') }
                            </div>
                            <div className="order_date text-center">
                                <label>
                                    { t('transactions.order_date') }
                                </label>
                                { moment(transaction.order_date).format('L') }
                            </div>
                        </Col>
                        <Col>
                            <div className="gross_amount text-center">
                                <strong>
                                    { `${ t('transactions.gross_amount') }: ${ transaction.gross_amount.toFixed(2)} ${transaction.currency}` }
                                </strong>
                            </div>
                            <div className="fees text-center">
                                { `${ t('transactions.psp_fees') }:
                                   ${(transaction.payment_system_fees && transaction.payment_system_fees.toFixed(2)) || '-'}
                                   ${transaction.currency}`
                                }
                            </div>
                            <div className="fees text-center">
                                { `${ t('transactions.acq_fees') }:
                                   ${ ((transaction.acquirer_fees && transaction.acquirer_fees.toFixed(2)) || '-')}
                                   ${transaction.currency}`
                                }
                            </div>
                            {transaction.acquirer.customer_contract && transaction.gross_amount && (
                            <div className="customer-contract alert alert-info text-center">
                                <strong>
                                    { transaction.gross_amount.toFixed(2) }{' '}
                                    { transaction.currency }{' '}
                                    { t('transactions.will_be_paid_by')}{' '}
                                    { transaction.acquirer.name }
                                </strong>
                            </div>
                            )}
                            <div className="net_amount text-center">
                                <strong>{ `${ t('transactions.net_amount') }:
                                    ${ ((transaction.net_amount && transaction.net_amount.toFixed(2)) || '-')}
                                    ${transaction.currency}` }
                                </strong>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <div className="payment_system text-center">
                                <label>
                                    { t('transactions.payment_system') }
                                </label>
                                { transaction.payment_system.name }
                            </div>
                            <div className="acquirer text-center">
                                <label>
                                    { t('transactions.acquirer') }
                                </label>
                                { transaction.acquirer.name }
                            </div>
                            <div className="device text-center">
                                <label>
                                    { t('transactions.device') }
                                </label>
                                { (transaction.device || {}).name }
                            </div>
                        </Col>
                        <Col>
                            <div className="account text-center">
                                <label>
                                    { t('transactions.account') }
                                </label>
                                { (transaction.account || {}).name }
                            </div>
                            <div className="card_type text-center">
                                <label>
                                    { t('transactions.card_type') }
                                </label>
                                { transaction.card_type.name }
                            </div>
                            <div className="card_number text-center">
                                <label>
                                    { t('transactions.card_number') }
                                </label>
                                { transaction.card_number }
                            </div>
                        </Col>
                    </Row>
                    {(transaction.linked_transactions || []).length > 0 && (
                    <Row>
                        <Col>
                            <hr/>
                            <h4 className="text-center">{ t('transactions.linked_transactions') }</h4>
                            <TransactionsListing
                                transactions={ transaction.linked_transactions }
                            />
                        </Col>
                    </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col>
                                { hasRefundingError && (
                                    <ErrorMessage message={ refundingErrorMessage } />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            { refundableAmount > 0 && (
                            <Col sm={8}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <Button
                                            color="danger"
                                            onClick={do_refund}
                                            disabled={ refundAmount <= 0 || refundAmount > refundableAmount }
                                        >
                                            <Icon path={ mdiCreditCardRefundOutline } size={1} color="white" />
                                            { t('transactions.refund') }
                                        </Button>
                                    </InputGroupAddon>
                                    <Input
                                        type="number"
                                        defaultValue={ refundableAmount.toFixed(2) }
                                        valid={ refundAmount > 0 && refundAmount <= refundableAmount }
                                        onChange={(e) => { setRefundAmound(e.target.value)}}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>{ transaction.currency }</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            )}
                            <Col sm={refundableAmount > 0 ? 4 : 12} className="text-right">
                                <Button color="secondary" onClick={ onClose }>
                                    <Icon path={ mdiClose } size={1} color="white" />
                                    { t('common.close') }
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </>
            )}
        </Modal>
    );
}

export default TransactionModal;
