import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiPassportBiometric, mdiPlusCircleOutline, mdiPencil } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { loadDevices } from '../../../redux/modules/devices';
import StyledTable from '../../../components/StyledTable/StyledTable';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import moment from 'moment';

const DevicesList = () => {
    const dispatch               = useDispatch();
    const { path }               = useRouteMatch();
    const { t }                  = useTranslation();
    const { isLoading, devices } = useSelector(state => state.devices);

    useEffect(() => {
        dispatch(loadDevices());
    }, [dispatch]);

    if (!devices || isLoading) {
        return <FullPageLoader />
    }

    const cellFormatter = (text, smallText, capitalizeSmallText) =>
        <>
            <div>
                { text }
            </div>
            {smallText &&
                <small className={capitalizeSmallText ? 'capitalize': ''}>{ smallText }</small>
            }
        </>

    const actionFormatter = (id, device) =>
        <Button tag={ Link } to={ `${path}/edit/${id}` } color="success"><Icon path={ mdiPencil } size={ 1 } color="white" /></Button>;

    const columns = [{
        dataField: 'name',
        text: t('devices.name'),
        sort: true,
        formatter: (name, device) => cellFormatter(
            name,
            device.identifier
        )
    }, {
        dataField: 'account.name',
        text: t('devices.account'),
        sort: true
    }, {
        dataField: 'identifier',
        text: t('devices.identifier'),
        sort: true,
        hidden: true
    }, {
        dataField: 'type',
        text: t('devices.type'),
        sort: true
    }, {
        dataField: 'payment_system.name',
        text: t('devices.payment_system'),
        sort: true
    }, {
        dataField: 'start_at',
        text: t('devices.start_at'),
        sort: true,
        formatter: (start_at, row) => cellFormatter(
            moment(start_at).format('lll')
        ),
        csvFormatter: (cell, row, rowIndex, formatExtraData) => {
            return moment(cell).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'stop_at',
        text: t('devices.stop_at'),
        sort: true,
        formatter: (stop_at, row) => cellFormatter(
            moment(stop_at).format('lll')
        ),
        csvFormatter: (cell, row, rowIndex, formatExtraData) => {
            return moment(cell).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];

    const actions = <Link to={`${path}/new`}>
        <Button color="info">
            <Icon path={ mdiPlusCircleOutline } size={ 1 } color="white" /> { t('devices.add') }
        </Button>
    </Link>;

    return (
        <div className="DevicesList">
            <PageTitle
                title={ t('devices.list_title') }
                icon={ <Icon path={ mdiPassportBiometric } color="white" /> }
                actions={ actions }
            />
            <StyledTable
                bootstrap4
                keyField="id"
                data={ devices }
                columns={ columns }
                defaultSorted={ defaultSorted }
                sizePerPage= { 25 }
            />
        </div>
    );
}

export default DevicesList;
