import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledTable from '../StyledTable/StyledTable';
import TransactionModal from '../TransactionModal/TransactionModal';
import Icon from '@mdi/react';
import { mdiCalendar, mdiCash, } from '@mdi/js';

import moment from 'moment';

import './TransactionsListing.css';

const TransactionsListing = ({transactions, ...otherProps}) => {
    const { t }               = useTranslation();

    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const hideTransactionDetails = (transaction) => {
        setShowModal(false);
        setCurrentTransaction(null);
    };

    const columns = [{
        dataField: 'type',
        text: t('transactions.type'),
        classes: (type, row) => `${type} with-left-icon ${row.status}`,
        formatter: (type, row, rowIndex, formatExtraData) => {
            return (
                <>
                    <div>{ t(`transactions.types.${type}`) }</div>
                    <small>
                        <Icon path={row.type_icon(type)} size={1.5} color="#121212" className="left-icon" />
                        { t(`transactions.statuses.${row.status}`) }
                    </small>
                </>
            );
        }
    }, {
        dataField: 'transaction_date',
        text: t('transactions.transaction_date'),
        sort: true,
        classes: (type, row) => 'with-left-icon',
        formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
                <>
                    <Icon path={mdiCalendar} size={1.5} color="white" className="left-icon" />
                    <div>{ moment(cell).format('lll') }</div>
                    <small>{ row.order_id }</small>
                </>
            );
        },
        csvFormatter: (cell, row, rowIndex, formatExtraData) => {
            return moment(cell).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'gross_amount',
        text: t('transactions.gross_amount'),
        sort: true,
        csvType: Number,
        classes: (type, row) => 'with-left-icon',
        formatter: (cell, row, rowIndex) => {
            return (
                <>
                    <Icon path={mdiCash} size={1.5} color="white" className="left-icon" />
                    <div>{ cell.toFixed(2) + ' ' + row.currency }</div>
                    <small>Net: { ((row.net_amount && row.net_amount.toFixed(2)) || '-') + ' ' + row.currency }</small>
                </>
            );
        }
    }];

    const defaultSorted = [{
        dataField: 'transaction_date',
        order: 'desc'
    }];

    return (
        <div className="TransactionsListing">
            {currentTransaction &&
            <TransactionModal
                show={ showModal }
                transaction_id={ currentTransaction.id }
                onClose={ hideTransactionDetails }
            />
            }
            <StyledTable
                data={ transactions }
                columns={ columns }
                defaultSorted={ defaultSorted }
                hideSearch
                hideExport
                { ...otherProps }
            />
        </div>
    );
}

export default TransactionsListing;
