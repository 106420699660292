import BaseModel from './BaseModel';

/** Class representing a batch. */
class Batch extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a batchs listing
 */
Batch.getListingFields = () => [
    'id',
    'created_at',
    'created_by',
    'filename',
    'manager',
    'nb_saved',
    'nb_valid',
    'nb_duplicates',
    'nb_invalid',
    'success',
    'errors',
];

export default Batch;
