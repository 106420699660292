import React from 'react';

import './Widget.css';

const Widget = ({title, value, action}) => {
    return (
        <div className="Widget">
            <div className="header">
                <h3>{ value }</h3>
            </div>
            <div className="body">
                <span>{ title }</span>
                { action && (
                <div className="action noPrint">
                    { action }
                </div>
                )}
            </div>
        </div>
    );
}

export default Widget;
