import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiMonitorDashboard, mdiPassportBiometric } from '@mdi/js';
import PageTitle from '../../components/PageTitle/PageTitle';
import HistoryCard from './HistoryCard/HistoryCard';
import BalanceCard from './BalanceCard/BalanceCard';
import LastTransactionsCard from './LastTransactionsCard/LastTransactionsCard';
import RevenuesStatsCard from './RevenuesStatsCard/RevenuesStatsCard';
import { updateValues } from '../../redux/modules/filters';

const Dashboard = () => {
    const dispatch = useDispatch();

    const { t }    = useTranslation();
    const { user } = useSelector(state => state.auth);

    const { values }   = useSelector(state => state.filters);
    const { accounts } = useSelector(state => state.accounts);
    const [selectedAccount, setSelectedAccount] = useState(null);

    useEffect(() => {
        if (user)
            dispatch(updateValues({
                ...values,
                accountId: (values.account_id || user.accountId)
            }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user]);

    const changedAccount = (accountId) => {
        dispatch(updateValues({
            ...values,
            account_id: accountId
        }));
        setSelectedAccount(accounts.find(account => account.id === accountId));
    }
    const actions = user.isSuperAdmin() ? (
        <InputGroup>
            <InputGroupAddon addonType="prepend">
                <span className="input-group-text">
                    <Icon path={mdiPassportBiometric} size={1} color="grey" />
                </span>
            </InputGroupAddon>
            <Input
                type="select"
                className="custom-select"
                onChange={(e) => changedAccount(e.target.value)}
                value={values.account_id}
            >
                <option key="0" value="">---</option>
                {accounts && accounts.map((a) =>
                    <option key={a.id} value={a.id}>{a.name}</option>
                )}
            </Input>
        </InputGroup>
    ) : null;

    return (
        <div className="Dashboard">
            <PageTitle
                title={ t('dashboard.title') }
                icon={ <Icon path={ mdiMonitorDashboard } color="white" /> }
                actions= {actions }
            />
            <Row>
                <Col md={12}>
                    <BalanceCard currency={ selectedAccount?.currency || 'CHF' } accountId={ values.account_id }/>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={12}>
                    <RevenuesStatsCard currency={ selectedAccount?.currency || 'CHF' } accountId={ values.account_id }/>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={8}>
                    <HistoryCard accountId={ values.account_id }/>
                </Col>
                <Col md={4}>
                    <LastTransactionsCard accountId={ values.account_id }/>
                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;
