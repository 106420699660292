import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiDomain, mdiDomainPlus, mdiPencil } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { loadAcquirers } from '../../../redux/modules/acquirers';
import StyledTable from '../../../components/StyledTable/StyledTable';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import PageTitle from '../../../components/PageTitle/PageTitle';

const AcquirersList = () => {
    const dispatch                = useDispatch();
    const { path }                = useRouteMatch();
    const { t }                   = useTranslation();
    const { isLoading, acquirers } = useSelector(state => state.acquirers);

    useEffect(() => {
        dispatch(loadAcquirers());
    }, [dispatch]);

    if (!acquirers || isLoading) {
        return <FullPageLoader />
    }

    const actionFormatter = (id, acquirer) =>
        <Button tag={ Link } to={ `${path}/edit/${id}` } color="success"><Icon path={ mdiPencil } size={ 1 } color="white" /></Button>;

    const columns = [{
        dataField: 'name',
        text: t('acquirers.name'),
        sort: true,
        formatter: (name) => <strong>{ name }</strong>
    }, {
        dataField: 'payment_system.name',
        text: t('acquirers.payment_system'),
        sort: true
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }];

    const defaultSorted = [{
        dataField: 'fullname',
        order: 'desc'
    }];

    const actions = <Link to={`${path}/new`}>
        <Button color="info">
            <Icon path={ mdiDomainPlus } size={ 1 } color="white" /> { t('acquirers.add') }
        </Button>
    </Link>;

    return (
        <div className="AcquirersList">
            <PageTitle
                title={ t('acquirers.list_title') }
                icon={ <Icon path={ mdiDomain } color="white" /> }
                actions={ actions }
            />
            <StyledTable
                bootstrap4
                keyField="id"
                data={ acquirers }
                columns={ columns }
                defaultSorted={ defaultSorted }
                sizePerPage= { 25 }
            />
        </div>
    );
}

export default AcquirersList;
