import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle/PageTitle';
import FileUploader from '../../../components/FileUploader/FileUploader';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import Icon from '@mdi/react';
import {
    mdiArrowLeftCircle,
    mdiCloudUploadOutline,
    mdiContentSaveOutline,
    mdiCheck,
    mdiContentDuplicate,
    mdiAlertCircleOutline
} from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { importTransactions } from '../../../redux/modules/transactions';

import './TransactionsImport.css';

const TransactionsImport = () => {
    const dispatch     = useDispatch();
    const { path }     = useRouteMatch();
    const { t }        = useTranslation();
    const { managers } = useSelector((state) => state.transactions);

    const {
        importedTransactions,
        isImporting,
        hasImportingError,
        importingErrorMessage
    } = useSelector(state => state.transactions);

    const uploadFiles = (files, manager_id) => {
        // Get the manager instance by its id
        const manager = (managers.filter(m => m.id === manager_id) || [])[0];

        files.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                let data = reader.result;
                if (manager.accepts_binary)
                    data = data.replace(/^[^,]*,/, '');
                dispatch(importTransactions(data, manager.id));
            }

            if (manager.accepts_binary)
                reader.readAsDataURL(file, manager.encoding)
            else
                reader.readAsText(file, manager.encoding)
        })
    }

    const top_actions =
        <Link to={`${path.replace('import', '')}`}>
            <Button color="info">
                <Icon path={mdiArrowLeftCircle} size={1} color="white" /> { t('transactions.back_button') }
            </Button>
        </Link>;

    return (
        <div className="TransactionsImport">
            <PageTitle
                title="Importer des transactions"
                icon={<Icon path={mdiCloudUploadOutline} color="white" />}
                actions={top_actions}
            />
            {isImporting &&
                <FullPageLoader />
            }
            {!isImporting &&
                <FileUploader uploadFiles={uploadFiles} />
            }
            {hasImportingError &&
                <div className="alert alert-danger">
                    {importingErrorMessage}
                </div>
            }
            {importedTransactions &&
                <div className="import-results">
                    <Row>
                        <Col sm={{offset: 4, size: 4}}>
                            <div className="alert alert-primary mb-5">
                                <strong>
                                    <Icon path={mdiContentSaveOutline} size={1} color="#004085" />
                                    {importedTransactions.saved.length} {t('transactions.saved_transactions')}
                                </strong>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {importedTransactions.valid.length > 0 &&
                        <Col sm={{offset: 4, size: 4}}>
                            <div className="alert alert-success">
                                <Icon path={mdiCheck} size={1} color="#155724" />
                                <strong>{importedTransactions.valid.length} {t('transactions.valid_transactions')}</strong>
                            </div>
                        </Col>
                        }
                    </Row>
                    <Row>
                        {importedTransactions.duplicates.length > 0 &&
                        <Col sm={{offset: 4, size: 4}}>
                            <div className="alert alert-warning">
                                <Icon path={mdiContentDuplicate} size={1} color="#856404" />
                                <strong>{importedTransactions.duplicates.length} {t('transactions.ignored_transactions')}</strong>
                            </div>
                        </Col>
                        }
                    </Row>
                    <Row>
                        {importedTransactions.invalid.length > 0 &&
                        <Col sm={{offset: 4, size: 4}}>
                            <div className="alert alert-danger">
                                <Icon path={mdiAlertCircleOutline} size={1} color="#721c24" />
                                <strong>{importedTransactions.invalid.length} {t('transactions.invalid_transactions')}</strong>
                                {importedTransactions.invalid.length > 0 && importedTransactions.invalid.map((t) => (
                                    <div key={t.index} className="transaction invalid">
                                        Ligne {t.index}: {Object.keys(t.errors).map(k => (k + ' (' + t.errors[k].join(' / ') + ')'))}
                                    </div>
                                ))}
                            </div>
                        </Col>
                        }
                    </Row>
                </div>
            }
        </div>
    );
}

export default TransactionsImport;
