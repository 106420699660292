import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { getLastConnectedUser } from '../../redux/modules/auth';
import Login from '../Login/Login';
import TopBar from './TopBar/TopBar';
import MainContent from './MainContent/MainContent';
import FullPageLoader from '../../components/FullPageLoader/FullPageLoader';
import { loadAccounts } from '../../redux/modules/accounts';
import { loadAcquirers } from '../../redux/modules/acquirers';
import { loadCardTypes } from '../../redux/modules/card_types';
import { loadDevices } from '../../redux/modules/devices';
import { loadPaymentSystems } from '../../redux/modules/payment_systems';
import { loadRates } from '../../redux/modules/rates';
import { getManagers } from '../../redux/modules/transactions';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './App.css';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const App = () => {
    const user = useSelector(state => state.auth.user);
    const isAuthenticating = useSelector(state => state.auth.isAuthenticating);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLastConnectedUser());
    }, [dispatch]);

    useEffect(() => {
        if (user && user.id) {
            dispatch(loadAccounts());
            dispatch(loadAcquirers());
            dispatch(loadCardTypes());
            dispatch(loadDevices());
            dispatch(loadPaymentSystems());
            dispatch(loadRates());
            dispatch(getManagers());
        }
    }, [dispatch, user]);

    if (isAuthenticating) {
        return <FullPageLoader />;
    }

    if (!user || !('id' in user)) {
        return <Login />;
    }

    return (
        <div className="App">
            <TopBar />
            <Container fluid>
                <Row>
                    <Col>
                        <MainContent />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default App;
