import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { Device } from '../../../models';

import './DeviceForm.css';

let DeviceForm = ({ isCreation, handleSubmit, pristine, submitting }) => {
    const { t }                 = useTranslation();
    const { accounts }          = useSelector((state) => state.accounts);
    const { payment_systems }   = useSelector((state) => state.payment_systems);
    const { isLoading }         = useSelector( state => state.devices);

    return (
        <Form className="deviceForm" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('devices.add_title') : t('devices.edit_title') }</CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="type">{ t('devices.type') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('devices.type') } name="type" id="type" required>
                            { isCreation && <option>---</option> }
                            { Device.types().map(type => <option key={ type } value={ type }>{ t(`devices.types.${type}`) }</option>) }
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="name">{ t('devices.name') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('devices.name') } name="name" id="name" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="identifier">{ t('devices.identifier') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('devices.identifier') } name="identifier" id="identifier" required />
                    </FormGroup>

                    { !isCreation &&
                        <FormGroup>
                            <Label for="key_in">{ t('devices.key_in') }</Label>
                            <Field component="input" className="form-control" placeholder={ t('devices.key_in') } name="key_in" id="key_in" disabled required />
                        </FormGroup>
                    }

                    { !isCreation &&
                        <FormGroup>
                            <Label for="key_out">{ t('devices.key_out') }</Label>
                            <Field component="input" className="form-control" placeholder={ t('devices.key_out') } name="key_out" id="key_out" disabled required />
                        </FormGroup>
                    }

                    <FormGroup>
                        <Label for="account_id">{ t('devices.account') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('devices.account_id') } name="account_id" id="account_id" required>
                            { isCreation && <option>---</option> }
                            { accounts && accounts.map(account => <option key={ account.id } value={ account.id }>{ account.name }</option>) }
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="payment_system_id">{ t('devices.payment_system') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('devices.payment_system_id') } name="payment_system_id" id="payment_system_id" required>
                            { isCreation && <option>---</option> }
                            { payment_systems && payment_systems.map(payment_system => <option key={ payment_system.id } value={ payment_system.id }>{ payment_system.name }</option>) }
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="start_at">{ t('devices.start_at') }</Label>
                        <Field
                            component={ DateTimePicker }
                            type="text"
                            name="start_at"
                            id="start_at"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="stop_at">{ t('devices.stop_at') }</Label>
                        <Field
                            component={ DateTimePicker }
                            type="text"
                            name="stop_at"
                            id="stop_at"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="notify">
                            { `${t('devices.notify')} (${t('devices.comma_separated_emails')})` }
                        </Label>
                        <Field
                            component="textarea"
                            className="form-control"
                            name="notify"
                            id="notify"
                        />
                    </FormGroup>

                    <FormGroup check>
                        <Label check>
                            <Field
                                component="input"
                                type="checkbox"
                                name="can_refund"
                                id="can_refund"
                            /> { t('devices.can_refund') }
                        </Label>
                    </FormGroup>
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading }>
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

DeviceForm = reduxForm({
    form: 'deviceForm',
    enableReinitialize: true,
})(DeviceForm)

DeviceForm = connect(
    state => ({
        initialValues: state.devices.device
    })
)(DeviceForm)

export default DeviceForm;
