import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiBriefcaseAccountOutline, mdiBriefcasePlus, mdiPencil } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { loadAccounts } from '../../../redux/modules/accounts';
import StyledTable from '../../../components/StyledTable/StyledTable';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import PageTitle from '../../../components/PageTitle/PageTitle';

const AccountsList = () => {
    const dispatch                = useDispatch();
    const { path }                = useRouteMatch();
    const { t }                   = useTranslation();
    const { isLoading, accounts } = useSelector(state => state.accounts);

    useEffect(() => {
        dispatch(loadAccounts());
    }, [dispatch]);

    if (!accounts || isLoading) {
        return <FullPageLoader />
    }

    const actionFormatter = (id, account) =>
        <Button tag={ Link } to={ `${path}/edit/${id}` } color="success"><Icon path={ mdiPencil } size={ 1 } color="white" /></Button>;

    const columns = [{
        dataField: 'name',
        text: t('accounts.name'),
        sort: true
    }, {
        dataField: 'iban',
        text: t('accounts.iban'),
        sort: true
    }, {
        dataField: 'currency',
        text: t('accounts.currency'),
        sort: true
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }];

    const defaultSorted = [{
        dataField: 'fullname',
        order: 'desc'
    }];

    const actions = <Link to={`${path}/new`}>
        <Button color="info">
            <Icon path={ mdiBriefcasePlus } size={ 1 } color="white" /> { t('accounts.add') }
        </Button>
    </Link>;

    return (
        <div className="AccountsList">
            <PageTitle
                title={ t('accounts.list_title') }
                icon={ <Icon path={ mdiBriefcaseAccountOutline } color="white" /> }
                actions={ actions }
            />
            <StyledTable
                bootstrap4
                keyField="id"
                data={ accounts }
                columns={ columns }
                defaultSorted={ defaultSorted }
                sizePerPage= { 25 }
            />
        </div>
    );
}

export default AccountsList;
