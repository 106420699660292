import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CardTypeCreate from './CardTypeCreate/CardTypeCreate';
import CardTypeEdit from './CardTypeEdit/CardTypeEdit';
import CardTypesList from './CardTypesList/CardTypesList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const CardTypes = () => {
    const { path } = useRouteMatch();
    return (
        <div className="CardTypes">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><CardTypesList /></Route>
                    <Route exact path={`${path}/new`}><CardTypeCreate /></Route>
                    <Route exact path={`${path}/edit/:cardTypeId`}><CardTypeEdit /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default CardTypes;
