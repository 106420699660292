import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

import './FullPageLoader.css';

const FullPageLoader = () => {
    return (
        <Container className="FullPageLoader vh-100">
            <Row className="vh-100 justify-content-center">
                <Col className="align-self-center spinner-wrapper">
                    <Icon path={mdiLoading} color="white" spin={1} />
                </Col>
            </Row>
        </Container>
    );
}

export default FullPageLoader;
