import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiPassportBiometric, mdiArrowLeftCircle } from '@mdi/js';
import { useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import DeviceForm from '../DeviceForm/DeviceForm';
import { createDevice, startCreateDevice } from '../../../redux/modules/devices';

const DeviceCreate = () => {
    const dispatch   = useDispatch();
    const { path }   = useRouteMatch();
    const { t }      = useTranslation();
    const { device } = useSelector(state => state.devices);
    const { deviceSuccessfullyCreated } = useSelector(state => state.devices);

    useEffect(() => {
        dispatch(startCreateDevice());
    }, [dispatch]);

    if (deviceSuccessfullyCreated && device) {
        return <Redirect to={ `edit/${device.id}` } />
    }

    const actions = <Link to={ `${path.replace('new', '')}` }>
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleDeviceCreation = (values) => {
        dispatch(createDevice(values));
    };

    return (
        <div className="DeviceCreate">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('devices.add_title') }
                        icon={ <Icon path={mdiPassportBiometric} color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <DeviceForm onSubmit={ handleDeviceCreation } isCreation={ true } />
                </Col>
            </Row>
        </div>
    );
}

export default DeviceCreate;
