import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiTimelineClockOutline } from '@mdi/js';
import { getFinancialHistory } from '../../../redux/modules/transactions';
import FinancialHistory from '../../../components/FinancialHistory/FinancialHistory';
import Loader from '../../../components/Loader/Loader';
import PageTitle from '../../../components/PageTitle/PageTitle';

const HistoryCard = ({ accountId }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);

    const {
        isGettingFinancialHistory,
        financialHistory
    } = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch(getFinancialHistory({
            'account_id': accountId
        }));
    }, [dispatch, accountId]);

    if (!financialHistory || isGettingFinancialHistory) {
        return <Loader />
    }

    return (
        <Card className="p-3">
            <PageTitle
                title={ t('financialHistory.title') }
                icon={ <Icon path={ mdiTimelineClockOutline } color="white" /> }
                headingSize={4}
            />
            <FinancialHistory
                history={ financialHistory }
                canFixBalances={ user.isSuperAdmin() }
            />
        </Card>
    );
}

export default HistoryCard;
