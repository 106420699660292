import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Report } from '../../../../models';
import StyledTable from '../../../../components/StyledTable/StyledTable';

const ReportResultType = ({ stats, currency }) => {
    const { t } = useTranslation();

    const columns = [{
        dataField: 'type',
        sort: true,
        text: <span className="onlyPrint">Type</span>,
        classes: 'text-capitalize'
    }, {
        dataField: 'nb',
        sort: true,
        text: t('reports.transactions')
    }, {
        dataField: 'total.effective',
        sort: true,
        text: t('reports.revenues'),
        formatter: effective => Report.format_money(effective, currency)
    }, {
        dataField: 'total',
        sort: true,
        text: t('reports.fees'),
        formatter: total => Report.format_money(total.effective - total.net, currency)
    }];

    return (
        <Row>
            <Col>
                <StyledTable
                    data={ stats }
                    columns={ columns }
                    hideSearch
                    hideExport
                />
            </Col>
        </Row>
    );
}

export default ReportResultType;
