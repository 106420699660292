import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TransactionsList from './TransactionsList/TransactionsList';
import TransactionsImport from './TransactionsImport/TransactionsImport';
import TransactionCreate from './TransactionCreate/TransactionCreate';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Transactions = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Transactions">
            <Switch>

                <>
                    <Role has={ User.ROLE_USER }>
                        <Route exact path={path}><TransactionsList /></Route>
                    </Role>

                    <Role has={ User.ROLE_SUPER_ADMIN }>
                        <Route exact path={`${path}/import`}><TransactionsImport /></Route>
                        <Route exact path={`${path}/new`}><TransactionCreate /></Route>
                    </Role>
                </>

            </Switch>
        </div>
    );
}

export default Transactions;
