import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';

let AcquirerForm = ({ isCreation, handleSubmit, pristine, submitting }) => {
    const { t }         = useTranslation();
    const { isLoading } = useSelector(state => state.acquirers);
    const { payment_systems } = useSelector((state) => state.payment_systems);

    return (
        <Form className="form" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('acquirers.add_title') : t('acquirers.edit_title') }</CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="name">{ t('acquirers.name') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('acquirers.name') } name="name" id="name" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="iban">{ t('acquirers.payment_system') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('acquirers.payment_system_id') } name="payment_system_id" id="payment_system_id" required>
                            { (payment_systems || []).map(psp => <option key={ psp.id } value={ psp.id }>{psp.name}</option>) }
                        </Field>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Field
                                component="input"
                                type="checkbox"
                                name="customer_contract"
                                id="customer_contract"
                            /> { t('acquirers.customer_contract') }
                        </Label>
                    </FormGroup>
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading }>
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

AcquirerForm = reduxForm({
    form: 'acquirerForm',
    enableReinitialize: true,
})(AcquirerForm)

AcquirerForm = connect(
    state => ({
        initialValues: state.acquirers.acquirer
    })
)(AcquirerForm)

export default AcquirerForm;
