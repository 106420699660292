import BaseModel from './BaseModel';
import Payment from './Payment';

/** Class representing an user. */
class FinancialHistory extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.payments.length)
            this.payments = this.payments.map(payment => new Payment(payment));
    }
}

export default FinancialHistory;
