import BaseModel from './BaseModel';

/** Class representing a device. */
class Device extends BaseModel {}

Device.TYPE_ADMIN   = 'ADMIN';
Device.TYPE_TPE     = 'TPE';
Device.TYPE_VIRTUAL = 'VIRTUAL';

Device.types = () => {
    return [
        Device.TYPE_ADMIN,
        Device.TYPE_TPE,
        Device.TYPE_VIRTUAL
    ];
}

/**
 * Get the fields to get server side when we
 * ask for a devices listing
 */
Device.getListingFields = () => [
    'id',
    'type',
    'name',
    'identifier',
    'start_at',
    'stop_at',
    'payment_system_id',
    'payment_system.name',
    'account_id',
    'account.name'
];

export default Device;
