import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import defaultTheme from '../theme';
import { merge } from 'lodash';

const BarChart = (props) => {
    const {
        data,
        keys,
        axisBottomLegend,
        axisBottomTickRotation,
        axisLeftLegend,
        axisLeftFormat,
        labelFormat,
        tooltipFormat,
        enableLabel,
        theme,
        scheme,
    } = props;

    const axisBottom = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: axisBottomTickRotation,
        legend: axisBottomLegend,
        legendPosition: 'middle',
        legendOffset: 45
    };

    const axisLeft = axisLeftLegend ? {
        tickSize: 5,
        tickPadding: 5,
        legend: axisLeftLegend,
        legendPosition: 'middle',
        legendOffset: -45,
        format: axisLeftFormat,
    } : null;

    const legends = [{
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemTextColor: '#fff',
    }];

    const marginBottom = axisBottomTickRotation === 0 ? 50 : 100;
    const chartTheme   = merge(defaultTheme, theme);

    return (
        <ResponsiveBar
            data={ data }
            keys={ keys }
            padding={ 0.3 }
            margin={{
                top: 30,
                right: 130,
                bottom: marginBottom,
                left: 60
            }}
            colors={{ scheme: scheme }}
            theme={ chartTheme }
            legends={ legends }
            axisBottom={ axisBottom }
            axisLeft={ axisLeft }
            labelSkipWidth={ 12 }
            labelSkipHeight={ 12 }
            tooltipFormat={ tooltipFormat }
            labelFormat={ labelFormat }
            enableLabel={ enableLabel }
        />
    );
}

BarChart.defaultProps = {
    axisBottomLegend: null,
    axisBottomTickRotation: 0,
    axisBottomFormat: undefined,
    axisLeftLegend: null,
    axisLeftFormat: undefined,
    enableLabel: true,
    theme: {},
    scheme: 'red_yellow_green',
};

BarChart.propTypes = {
    axisBottomLegend: PropTypes.string,
    axisBottomTickRotation: PropTypes.number,
    axisBottomFormat: PropTypes.func,
    axisLeftLegend: PropTypes.string,
    axisLeftFormat: PropTypes.func,
    enableLabel: PropTypes.bool,
    theme: PropTypes.object,
    scheme: PropTypes.string,
};

export default BarChart;
