import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import DeviceCreate from './DeviceCreate/DeviceCreate';
import DeviceEdit from './DeviceEdit/DeviceEdit';
import DevicesList from './DevicesList/DevicesList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Devices = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Devices">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><DevicesList /></Route>
                    <Route exact path={`${path}/new`}><DeviceCreate /></Route>
                    <Route exact path={`${path}/edit/:deviceId`}><DeviceEdit /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default Devices;
