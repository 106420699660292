import store from '../../redux/configureStore';
import RestClient from './RestClient';
import { Batch } from '../../models';

class Batchs extends RestClient {
    constructor() {
        super({
            model: Batch,
            entryPoint: 'batch',
            resource: 'batch',
            resources: 'batchs',
            sortBy: 'created_at'
        });
    }

    getDownloadFileLink(batch) {
        const user = store.getState().auth.user;

        return `${this.baseUrl}/batch/download_file/x-api-key/${user.api_key}/id/${batch.id}`;
    }

    run(id) {
        return this.POST('/batch/rerun', { id })
            .then(response => response.json())
            .then(json => {
                if (json.batch)
                    return json;
                throw new Error(json.flash.error);
            });
    }
}

const BatchsApi = new Batchs();

export default BatchsApi;
