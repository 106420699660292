/** Base class for all models. */
class BaseModel {
    constructor(properties) {
        Object.assign(this, properties || {});
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
BaseModel.getListingFields = () => [];

export default BaseModel;
