const theme = {
    axis: {
        legend: {
            text: {
                fill: '#fff',
            }
        },
        tickColor: '#fff',
        ticks: {
            text: {
                fill: '#fff',
            }
        }
    },
    background: '#171616',
    tooltip: {
        container: {
            background: '#000'
        },
    },
};

export default theme;

