import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import Icon from '@mdi/react';
import { mdiCloudUploadOutline, mdiBank } from '@mdi/js';

import './FileUploader.css';

const FileUploader = ({uploadFiles}) => {
    const { managers }                = useSelector((state) => state.transactions);
    const [ managerId, setManagerId ] = useState("");

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    return (
        <Container className="FileUploader">
            <Row>
                <Col>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>Veuillez déposer un fichier dans cette zone, ou cliquer pour en sélectionner un.</p>
                    </div>
                </Col>
            </Row>
            {acceptedFiles.length > 0 &&
            <>
                <Row className="mt-3">
                    <Col>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                    <Icon path={mdiBank} size={1} color="grey" />
                                </span>
                            </InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => setManagerId(e.target.value)}
                                value={managerId}
                            >
                                <option key="0" value="">---</option>
                                {managers && managers.map((m) =>
                                    <option key={m.id} value={m.id}>{m.name}</option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    {managerId &&
                    <Col>
                        <aside>
                            {acceptedFiles.map(file => (
                                <Button onClick={() => {uploadFiles(acceptedFiles, managerId)}} color="primary" key={file.path}>
                                    <Icon
                                        path={mdiCloudUploadOutline}
                                size={1}
                                        color="white"
                                    />
                                    Importer le fichier {file.path} - {file.size} bytes
                                </Button>
                            ))}
                        </aside>
                    </Col>
                    }
                </Row>
            </>
            }
        </Container>
    );
}

export default FileUploader;
