import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../locales/i18n';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { ButtonGroup, Button, Form, Label, FormGroup, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading, mdiArrowLeftCircle, } from '@mdi/js';
import Icon from '@mdi/react';
import DateTimePicker from '../../../../components/DateTimePicker/DateTimePicker';

import './RateForm.css';

const renderFees = ({ fields }) => {
    return fields.map((fee, i) => (
        <Row key={i}>
            <Col>
                <FormGroup>
                    <Label>{ i18n.t('rates.type') }</Label>
                    <Field component="select" className="form-control" name={`${fee}.type`} required>
                        <option>---</option>
                        { ['fixed', 'percentage'].map(type => (
                            <option
                                key={ type }
                                value={ type }
                            >
                                { i18n.t(`rates.types.${type}`) }
                            </option>
                        ))}
                    </Field>
                </FormGroup>
                <FormGroup>
                    <Label>{ i18n.t('rates.rate') }</Label>
                    <Field name={ `${fee}.rate` } className="form-control" type="text" component="input" />
                </FormGroup>
            </Col>
        </Row>
    ));
}

let RateForm = ({ closeForm, handleSubmit, pristine, submitting }) => {
    const { t }         = useTranslation();
    const { isLoading } = useSelector(state => state.rates);

    return (
        <Form className="RateForm" onSubmit={ handleSubmit }>
            <div className="text-right">
                <ButtonGroup>
                    <Button onClick={ closeForm } color="info" disabled={ submitting || isLoading } size="sm">
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> }
                        &nbsp;{t("common.close")}
                    </Button>
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading } size="sm">
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </ButtonGroup>
            </div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="start_at">{ t('rates.start_at') }</Label>
                        <Field
                            component={ DateTimePicker }
                            type="text"
                            name="start_at"
                            id="start_at"
                            timeFormat={ false }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="label"><b>{ t('rates.payment_system_fees') }</b></div>
                    <FieldArray name="payment_system_fees" component={ renderFees } />
                </Col>
                <Col>
                    <div className="label"><b>{ t('rates.acquirer_fees') }</b></div>
                    <FieldArray name="acquirer_fees" component={ renderFees } />
                </Col>
            </Row>
        </Form>
    );
}

RateForm = reduxForm({
    form: 'rateForm',
    enableReinitialize: true,
})(RateForm)

RateForm = connect(
    state => ({})
)(RateForm)

export default RateForm;
