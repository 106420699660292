import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiCreditCardSettingsOutline, mdiArrowLeftCircle } from '@mdi/js';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import CardTypeForm from '../CardTypeForm/CardTypeForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadCardType, updateCardType } from '../../../redux/modules/card_types';

const CardTypeEdit = () => {
    const dispatch       = useDispatch();
    const { t }          = useTranslation();
    const { cardTypeId } = useParams();

    useEffect(() => {
        dispatch(loadCardType(cardTypeId))
    }, [dispatch, cardTypeId])

     const actions = <Link to="/cardTypes">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleCardTypeEdition = (values) => {
        dispatch(updateCardType(values));
    };

    return (
         <div className="CardTypeEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('card_types.edit_title') }
                        icon={ <Icon path={ mdiCreditCardSettingsOutline } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <CardTypeForm
                        onSubmit={ handleCardTypeEdition }
                        isCreation={ false }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CardTypeEdit;
