import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import { Transaction } from '../../../models';

let AccountForm = ({ isCreation, handleSubmit, pristine, submitting }) => {
    const { t }         = useTranslation();
    const { isLoading } = useSelector(state => state.accounts);

    return (
        <Form className="form" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('accounts.add_title') : t('accounts.edit_title') }</CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="name">{ t('accounts.name') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('accounts.name') } name="name" id="name" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="iban">{ t('accounts.iban') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('accounts.iban') } name="iban" id="iban" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="currency">{ t('accounts.currency') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('accounts.currency') } name="currency" id="currency" required>
                            { Transaction.currencies().map(currency => <option key={ currency } value={ currency }>{currency}</option>) }
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="beneficiary_name">{ t('accounts.beneficiary_name') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('accounts.beneficiary_name') } name="beneficiary_name" id="beneficiary_name" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="beneficiary_address">{ t('accounts.beneficiary_address') }</Label>
                        <Field component="textarea" className="form-control" placeholder={ t('accounts.beneficiary_address') } name="beneficiary_address" id="beneficiary_address" />
                    </FormGroup>
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading }>
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

AccountForm = reduxForm({
    form: 'accountForm',
    enableReinitialize: true,
})(AccountForm)

AccountForm = connect(
    state => ({
        initialValues: state.accounts.account
    })
)(AccountForm)

export default AccountForm;
