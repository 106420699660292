import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import FiltersForm from '../FiltersForm/FiltersForm';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { resetAskPaymentAction } from '../../redux/modules/payments';
import {
    Container,
    Row, Col, Button, Modal,
    ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Icon from '@mdi/react';
import {
    mdiClose,
    mdiCashPlus
} from '@mdi/js';

import './AskPaymentModal.css';

const AskPaymentModal = ({askForPayment, toggle, show, hide}) => {
    const { t } = useTranslation();

    const { isAskingPayment, hasAskingPaymentError, askingPaymentErrorMessage } = useSelector(state => state.payments);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetAskPaymentAction());
    }, [dispatch, show]);

    return (
        <Modal backdrop={ true } size={'lg'} isOpen={ show } className="AskPaymentModal" toggle={ toggle }>
            { isAskingPayment && <Loader /> }

            { !isAskingPayment && (
            <>
                <ModalHeader toggle={ hide }>
                    <Icon path={ mdiCashPlus } color="white" size={1} />
                    {' '}{ t('dashboard.btn_new_payment') }
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FiltersForm askForPayment={ askForPayment }/>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col sm={8} className="text-left">
                                { hasAskingPaymentError && (
                                    <ErrorMessage message={ askingPaymentErrorMessage } />
                                )}
                            </Col>
                            <Col sm={4} className="text-right">
                                <Button color="secondary" onClick={ hide }>
                                    <Icon path={ mdiClose } size={1} color="white" />
                                    { t('common.close') }
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </>
            )}
        </Modal>
    );
}

export default AskPaymentModal;
