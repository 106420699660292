import Client from '../Client';
import _ from 'lodash';

class RestClient extends Client {
    constructor(config) {
        super();

        this.LIST_PATH   = '/list';
        this.GET_PATH    = '/get/id';
        this.UPDATE_PATH = '/update/id';
        this.CREATE_PATH = '/create';
        this.DELETE_PATH = '/delete/id';

        this.entryPoint = config.entryPoint;
        this.resource   = config.resource;
        this.resources  = config.resources
        this.model      = config.model;
        this.idField    = config.idField || 'id';
        this.sortBy     = config.sortBy;
    }

    list(filters) {
        // remove empty filter values
        filters = _.pickBy(filters, _.identity);

        // stringify fields
        const fields = (this.model.getListingFields() || []).join(',');

        return this.GET(`/${this.entryPoint}${this.LIST_PATH}`, { ...filters, fields })
            .then(response => response.json())
            .then(json => {
                if (json[this.resources]) {
                    json[this.resources] = json[this.resources].map(r => new this.model(r));

                    if (this.sortBy)
                        json[this.resources] = _.sortBy(json[this.resources], this.sortBy);

                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    get(id) {
        return this.GET(`/${this.entryPoint}${this.GET_PATH}/${id}`)
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    create(payload) {
        return this.POST(`/${this.entryPoint}${this.CREATE_PATH}`, { [this.resource]: payload })
            .then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    update(payload) {
        return this.PUT(
                `/${this.entryPoint}${this.UPDATE_PATH}/${payload[this.idField]}`,
                { [this.resource]: payload }
            ).then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    delete(id) {
        return this.POST(`/${this.entryPoint}${this.DELETE_PATH}/${id}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.flash && json.flash.error)
                    throw new Error(json.flash.error);

                return json;
            });
    }
}

export default RestClient;
