import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import { getBalance } from '../../../redux/modules/transactions';
import { askPaymentAction, resetAskPaymentAction } from '../../../redux/modules/payments';
import Report from '../../../models/Report';
import Loader from '../../../components/Loader/Loader';
import AskPayment from '../../../components/AskPayment/AskPayment';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import './BalanceCard.css';

const BalanceCard = ({ currency, accountId }) => {
    const dispatch         = useDispatch();
    const { askedPayment } = useSelector(state => state.payments);
    const { t }    = useTranslation();
    const {
        isGettingBalance,
        balance
    } = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch(getBalance({
            'account_id': accountId
        }));
    }, [dispatch, accountId]);

    useEffect(() => {
        if (!askedPayment)
            return;
        dispatch(resetAskPaymentAction());
    }, [dispatch, askedPayment]);

    const askForPayment = (filters) => {
        filters = _.pickBy(filters, _.identity);
        dispatch(askPaymentAction(accountId, filters, /*refresh*/true));
    }

    if (balance === undefined || isGettingBalance) {
        return <Loader />
    }

    return (
        <div className="Balance">
            <Card className="p-3 card-outline">
                <h1>
                    { Report.format_money(balance, currency) }
                    <small>
                        { t('balance_cards.due_to_date') }
                    </small>
                </h1>
            </Card>
            <AskPayment balance={ balance } askForPayment={ askForPayment } accountId={ accountId } />
        </div>
    );
}

export default BalanceCard;
