import { Auth } from '../../services';
import { Users } from '../../services';
import { User } from '../../models';

const LOG_IN                 = 'pixoo/auth/LOG_IN';
const LOG_IN_SUCCESS         = 'pixoo/auth/LOG_IN_SUCCESS';
const LOG_IN_FAILURE         = 'pixoo/auth/LOG_IN_FAILURE';
const LOG_OUT                = 'pixoo/auth/LOG_OUT';
const START_CHECKING_API_KEY = 'pixoo/auth/START_CHECKING_API_KEY';
const END_CHECKING_API_KEY   = 'pixoo/auth/END_CHECKING_API_KEY';
const UPDATING_USER          = 'pixoo/auth/UPDATING_USER';
const UPDATING_USER_SUCCESS  = 'pixoo/auth/UPDATING_USER_SUCCESS';
const UPDATING_USER_FAILURE  = 'pixoo/auth/UPDATING_USER_FAILURE';

const initialState = {
    user: {},
    isAuthenticating: false,
    isLoggingIn: false,
    logInError: false,
    logInErrorMessage: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                isLoggingIn: true,
                logInError: false
            };
        case LOG_IN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                user: action.user
            };
        case LOG_IN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                logInError: true,
                logInErrorMessage: action.error.message
            };
        case START_CHECKING_API_KEY:
            return {
                ...state,
                isAuthenticating: true
            };
        case END_CHECKING_API_KEY:
            return {
                ...state,
                isAuthenticating: false
            };
        case LOG_OUT:
            return {
                ...initialState,
            };
        case UPDATING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case UPDATING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    };
};

function logIn() { return { type: LOG_IN } }
function logOut() { return { type: LOG_OUT } }
function logInSuccess(user) { return { type: LOG_IN_SUCCESS, user: user } }
function logInFailure(err) { return { type: LOG_IN_FAILURE, error: err } }

function startCheckingApiKey() { return { type: START_CHECKING_API_KEY } }
function endCheckingApiKey() { return { type: END_CHECKING_API_KEY } }
export function getLastConnectedUser() {
    return (dispatch) => {
        dispatch(startCheckingApiKey());
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user)
            return dispatch(endCheckingApiKey());
        Auth.checkApiKey(user.api_key)
        .then(user => {
            if (user) {
                dispatch(logInSuccess(new User(user)));
                dispatch(endCheckingApiKey());
            } else {
                dispatch(endCheckingApiKey());
            }
        });
    }
}

export function authenticate(username, password) {
    return (dispatch) => {
        dispatch(logIn());
        Auth.logIn(username, password)
        .then(data => {
            const user = data.user;
            dispatch(logInSuccess(new User(user)));
        })
        .catch(err => {
            localStorage.setItem('user', null);
            dispatch(logInFailure(err))
        });
    }
}

export function logout() {
    return (dispatch) => {
        localStorage.setItem('user', null);
        dispatch(logOut());
    }
}

function updatingUser() { return { type: UPDATING_USER } }
function updatingUserSuccess(user) { return { type: UPDATING_USER_SUCCESS, user: user } }
function updatingUserFailure(err) { return { type: UPDATING_USER_FAILURE, error: err } }
const updateUser = user => {
    return (dispatch) => {
        dispatch(updatingUser());
        return Users.update(user)
            .then(data => {
                dispatch(updatingUserSuccess(user));
                JSON.parse(localStorage.setItem('user', JSON.stringify(user)));
            })
            .catch(err => {
                dispatch(updatingUserFailure(err))
            });
    }
};

export { updateUser };
