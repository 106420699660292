import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading, mdiCashRemove } from '@mdi/js';
import Icon from '@mdi/react';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { Transaction } from '../../../models';

let PaymentForm = ({ isCreation, handleSubmit, handleEnable, handleDelete, pristine, submitting }) => {
    const { t }                  = useTranslation();
    const { accounts }           = useSelector((state) => state.accounts);
    const currentUser            = useSelector(state => state.auth.user);
    const { payment, isLoading } = useSelector(
        state => state.payments
    );
    const [currentAccount, setCurrentAccount] = useState(null);

    const onAccountChange = (e) => {
        const account_id = e.target.value;
        const account    = (accounts.filter(a => a.id === account_id) || [])[0];
        setCurrentAccount(account);
    }
    const isDeletable = payment && !payment.completed && currentUser.isSuperAdmin();

    return (
        <Form className="form" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('payments.add_title') : t('payments.edit_title') }</CardTitle>
                        </Col>
                        <Col className="text-right">
                            { !isCreation && isDeletable &&
                                <Button color="danger" onClick={ () => { handleDelete(payment) } }>
                                    <Icon path={ mdiCashRemove } size={ 1 } color="white" /> { t('payments.delete') }
                                </Button> }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    { isCreation &&
                        <FormGroup>
                            <Label for="account">{ t('payments.account_id') }</Label>
                            <Field component="select" className="form-control" name="account_id" id="account_id" required onChange={ onAccountChange }>
                            { isCreation && <option>---</option> }
                            { accounts && accounts.map(account => <option key={ account.id } value={ account.id }>{ account.name }</option>) }
                            </Field>
                        </FormGroup>
                    }
                    { !isCreation &&
                        <FormGroup>
                            <Label for="account">{ t('payments.account_id') }</Label>
                            <Field component="select" className="form-control" name="account" id="account" disabled required>
                            <option>{ payment ? payment.account.name : '-' }</option>
                            </Field>
                        </FormGroup>
                    }

                    <FormGroup>
                            <Label for="iban">{ t('payments.iban') }</Label>
                            <Field component="input" className="form-control" placeholder={currentAccount ? currentAccount.iban : 'Iban'} name="iban" id="iban" />
                    </FormGroup>

                    <div className="row">
                        <FormGroup className="col-6">
                            <Label for="amount">{ t('payments.amount') }</Label>
                            <Field component="input" className="form-control" placeholder={ t('payments.amount') } name="amount" id="amount" required />
                        </FormGroup>

                        <FormGroup className="col-6">
                            <Label for="currency">{ t('payments.currency') }</Label>
                            <Field component="select" className="form-control" placeholder={ t('payments.currency') } name="currency" id="currency" required>
                            { Transaction.currencies().map(currency => <option key={ currency } value={ currency }>{currency}</option>) }
                            </Field>
                        </FormGroup>
                    </div>

                    <div className="row">
                        <FormGroup className="col-6">
                            <Label for="payment_date">{ t('payments.payment_date') }</Label>
                            <Field
                                component={ DateTimePicker }
                                type="text"
                                name="payment_date"
                                id="payment_date"
                                timeFormat={ false }
                            />
                        </FormGroup>

                        <FormGroup className="col-6">
                            <Label for="value_date">{ t('payments.value_date') }</Label>
                            <Field
                                component={ DateTimePicker }
                                type="text"
                                name="value_date"
                                id="value_date"
                                timeFormat={ false }
                            />
                        </FormGroup>
                    </div>

                    {!isCreation &&
                        <>
                            <FormGroup>
                                <Label for="balance_before">{ t('payments.balance_before') }</Label>
                                <Field disabled component="input" className="form-control" placeholder={ t('payments.balance_before') } name="balance_before" id="balance_before" required />
                            </FormGroup>

                            <FormGroup>
                                <Label for="total_transaction">{ t('payments.total_transaction') }</Label>
                                <Field disabled component="input" className="form-control" placeholder={ t('payments.total_transaction') } name="total_transaction" id="total_transaction" required />
                            </FormGroup>

                            <FormGroup>
                                <Label for="balance_after">{ t('payments.balance_after') }</Label>
                                <Field disabled component="input" className="form-control" placeholder={ t('payments.balance_after') } name="balance_after" id="balance_after" required />
                            </FormGroup>

                            <FormGroup>
                                <Label check>
                                    <Field
                                        component="input"
                                        type="checkbox"
                                        name="completed"
                                        id="completed"
                                    /> { t('payments.completed') }
                                </Label>
                            </FormGroup>
                        </>
                    }
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading }>
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

PaymentForm = reduxForm({
    form: 'paymentForm',
    enableReinitialize: true,
})(PaymentForm)

PaymentForm = connect(
    state => ({
        initialValues: state.payments.payment
    })
)(PaymentForm)

export default PaymentForm;
