import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getRevenuesStats } from '../../../redux/modules/transactions';
import Report from '../../../models/Report';
import Loader from '../../../components/Loader/Loader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { LineChart } from '../../../components/Chart';
import Icon from '@mdi/react';
import { mdiChartAreaspline } from '@mdi/js';
import moment from 'moment';

import './RevenuesStatsCard.css';

const RevenuesStatsCard = ({ currency, accountId }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const {
        isGettingRevenuesStats,
        revenuesStats
    } = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch(getRevenuesStats({
            'account_id': accountId
        }));
    }, [dispatch, accountId]);

    if (!revenuesStats || isGettingRevenuesStats) {
        return <Loader />
    }

    const transformToLineChart = revenues => [{
        id: t('transactions.revenues'),
        data: revenues.map(stat => {
            return {
                x: moment(stat.month).format('MM') + ' ' + moment(stat.month).format('YYYY'),
                y: stat.net_amount
            };
        })
    }];

    if (!Array.isArray(revenuesStats) || revenuesStats.length === 0)
        return null;

    return (
        <div className="RevenuesStats">
            <Card className="p-3">
                <PageTitle
                    title={ t('transactions.revenues_stats') }
                    icon={ <Icon path={ mdiChartAreaspline } color="white" /> }
                    headingSize={4}
                />
                <LineChart
                    data={ transformToLineChart(revenuesStats) }
                    enableArea={ true }
                    enableGridX={ false }
                    enableGridY={ true }
                    curve={'cardinal'}
                    tickRotation={ 0 }
                    tooltip={({ point }) => {
                        return (
                            <div className="chart-tooltip">
                                { t('transactions.revenues') }: <strong>{ Report.format_money(point.data.y, currency) }</strong>
                            </div>
                        )
                    }}
                />
            </Card>
        </div>
    );
}

export default RevenuesStatsCard;
