import React from 'react';
import * as Datetime from 'react-datetime';

import './DateTimePicker.css';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment'

const DateTimePicker = ({ input, name, required, meta, ...other }) => {
    input.value = moment(input.value);

    const onChange = (value) => {
        input.onChange(value);
    }

    return (
        <div className="DateTimePicker">
            <Datetime
                name={name}
                onChange={ onChange }
                value={input.value}
                {...other}
            />
        </div>
    );
}

export default DateTimePicker;
