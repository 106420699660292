import React from 'react';
import { Row, Col } from 'reactstrap';

import './PageTitle.css';

const PageTitle = ({title, icon, actions, headingSize}) => {
    const HeadingTag = headingSize ? `h${headingSize}` : 'h1';
    return (
        <div className="PageTitle">
            <Row>
                <Col className="align-self-center" md={8}>
                    <HeadingTag>
                        <span className="icon">{icon}</span>
                        <span className="text">{title}</span>
                    </HeadingTag>
                </Col>
                <Col className="align-self-center text-right" md={4}>
                    <div className="actions">
                        {actions}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PageTitle;
