import RestClient from './RestClient';
import { Acquirer } from '../../models';

class Acquirers extends RestClient {
    constructor() {
        super({
            model: Acquirer,
            entryPoint: 'acquirer',
            resource:   'acquirer',
            resources:  'acquirers',
            sortBy: 'name'
        });
    }
}

const AcquirersApi = new Acquirers();

export default AcquirersApi;
