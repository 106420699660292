import RestClient from './RestClient';
import { Transaction, Manager, FinancialHistory } from '../../models';

import _ from 'lodash';

class Transactions extends RestClient {
    constructor() {
        super({
            model: Transaction,
            entryPoint: 'transaction',
            resource:   'transaction',
            resources:  'transactions'
        });

        // override it to allow to create only transactions
        // linked to Net Oxygen's PSP
        this.CREATE_PATH = '/create_for_netox';
    }

    importFromFile(data, manager) {
        return this.POST('/transaction/import', { data, manager })
            .then(response => response.json())
            .then(json => {
                if (json.transactions)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    getReport(filters) {
        // remove empty filter values
        filters = _.pickBy(filters, _.identity);

        return this.GET('/transaction/report', filters)
            .then(response => response.json())
            .then(json => {
                if (json.report)
                    return json;

                throw new Error(json.flash.error);
            });
    }

    getManagers() {
        return this.GET('/transaction/managers')
            .then(response => response.json())
            .then(json => {
                if (json.managers) {
                    json.managers = json.managers.map(
                        r => new Manager(r)
                    );
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    refund(transaction, amount) {
        // convert amount to cents
        amount *= 100;

        const { order_id } = transaction;

        return this.POST('/transaction/refund', { amount, order_id })
            .then(response => response.json())
            .then(json => {
                if (json.flash.error)
                    throw new Error(json.flash.error);

                return json;
            });
    }

    getFinancialHistory(filters) {
        // remove empty filter values
        filters = _.pickBy(filters, _.identity);

        return this.GET('/transaction/financial_history', filters)
            .then(response => response.json())
            .then(json => {
                if (json.financial_history) {
                    json.financialHistory = new FinancialHistory(json.financial_history);
                    return json;
                }

                throw new Error(json.flash.error);
            });
    }

    getBalance(filters) {
        // remove empty filter values
        filters = _.pickBy(filters, _.identity);

        return this.GET('/transaction/balance', filters)
            .then(response => response.json())
            .then(json => {
                if ('balance' in json)
                    return json;

                throw new Error(json.flash.error);
            });
    }

    getLastTransactions(filters) {
        // remove empty filter values
        filters = _.pickBy(filters, _.identity);
        filters.nb = filters.nb ?? 5;

        return this.GET('/transaction/last', filters)
            .then(response => response.json())
            .then(json => {
                if ('transactions' in json) {
                    json.transactions = json.transactions.map(t => new Transaction(t));
                    return json;
                }

                throw new Error(json.flash.error);
            });
    }

    getRevenuesStats(filters) {
        // remove empty filter values
        filters = _.pickBy(filters, _.identity);

        return this.GET('/transaction/revenues_stats', filters)
            .then(response => response.json())
            .then(json => {
                if ('revenues_stats' in json)
                    return json;

                throw new Error(json.flash.error);
            });
    }
}

const TransactionsApi = new Transactions();

export default TransactionsApi;
