import Client from './Client';

class Auth extends Client {
    checkApiKey(apiKey) {
        return this.GET('/user/profile', {}, {
            "X-API-Key": apiKey
        })
        .then(response => response.json())
        .then(json => {
            if (json.user) {
                localStorage.setItem('user', JSON.stringify(json.user));
                return json.user;
            }
            return null;
        });
    }

    logIn(email, password) {
        return this.POST('/user/login', {
            email,
            password
        })
        .then(response => response.json())
        .then(json => {
            if (json.user) {
                localStorage.setItem('user', JSON.stringify(json.user));
                return json;
            }
            throw new Error(json.flash.error);
        });
    }

    logOut() {
        return this.POST('/user/logout').then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Déconnexion impossible");
        });
    }
}

const AuthApi = new Auth();

export default AuthApi;