const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    isLoadingOne: false,
    hasLoadingOneError: false,
    loadingOneErrorMessage: '',
    isDeleting: false,
    hasDeleteError: false,
    deleteErrorMessage: '',
    hasFinishedDeleting: false,
};

const startCreateSlice = (service) => {
    const property = service.resource;

    const START_CREATING_ITEM = `pixoo/${property}/START_CREATING_ITEM`;

    const startCreateReducer = (state, action) => {
        switch (action.type) {
            case START_CREATING_ITEM:
                return {
                    ...state,
                    [property]: null,
                    isLoading: false,
                    isLoadingOne: false,
                    hasLoadingError: false,
                    hasLoadingOneError: false,
                    [`${property}SuccessfullyCreated`]: false,
                    [`${property}SuccessfullyDeleted`]: false,
                }
            default:
                return state;
        }
    };

    const startCreateAction = () => { return { type: START_CREATING_ITEM } };

    return { startCreateReducer, startCreateAction };
}

const createSlice = (service) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const CREATING_ITEM         = `pixoo/${property}/CREATING_ITEM`;
    const CREATING_ITEM_SUCCESS = `pixoo/${property}/CREATING_ITEM_SUCCESS`;
    const CREATING_ITEM_FAILURE = `pixoo/${property}/CREATING_ITEM_FAILURE`;

    const createReducer = (state, action) => {
        switch (action.type) {
            case CREATING_ITEM:
                return {
                    ...state,
                    isLoadingOne: true,
                    hasLoadingOneError: false,
                };
            case CREATING_ITEM_SUCCESS:
                return {
                    ...state,
                    isLoadingOne: false,
                    hasLoadingOneError: false,
                    [`${property}SuccessfullyCreated`]: true,
                    [property]: action[property],
                    [listProperty]: (state[listProperty] || []).push(action[property]) && state[listProperty]
                };
            case CREATING_ITEM_FAILURE:
                return {
                    ...state,
                    isLoadingOne: false,
                    hasLoadingOneError: true,
                    [`${property}SuccessfullyCreated`]: false,
                    loadingOneErrorMessage: action.error.message
                };
            default:
                return state;
        }
    }

    function creatingItem() { return { type: CREATING_ITEM } }
    function creatingItemSuccess(item) { return { type: CREATING_ITEM_SUCCESS, [property]: item } }
    function creatingItemFailure(err) { return { type: CREATING_ITEM_FAILURE, error: err } }
    const createAction = (item) => {
        return (dispatch) => {
            dispatch(creatingItem());
            return service.create(item)
                .then(data => {
                    const item = data[property];
                    dispatch(creatingItemSuccess(item));
                })
                .catch(err => {
                    dispatch(creatingItemFailure(err))
                });
        }
    };

    return { createReducer, createAction };
};

const getSlice = (service) => {
    const property = service.resource;

    const LOADING_ITEM         = `pixoo/${property}/LOADING_ITEM`;
    const LOADING_ITEM_SUCCESS = `pixoo/${property}/LOADING_ITEM_SUCCESS`;
    const LOADING_ITEM_FAILURE = `pixoo/${property}/LOADING_ITEM_FAILURE`;

    const getReducer = (state, action) => {
        switch (action.type) {
            case LOADING_ITEM:
                return {
                    ...state,
                    isLoadingOne: true,
                    hasLoadingOneError: false,
                    [`${property}SuccessfullyCreated`]: false,
                    [`${property}SuccessfullyDeleted`]: false,
                };
            case LOADING_ITEM_SUCCESS:
                return {
                    ...state,
                    isLoadingOne: false,
                    hasLoadingOneError: false,
                    loadingOneErrorMessage: '',
                    [property]: action[property]
                };
            case LOADING_ITEM_FAILURE:
                return {
                    ...state,
                    isLoadingOne: false,
                    hasLoadingOneError: true,
                    loadingOneErrorMessage: action.error.message
                };
            default:
                return state;
        }
    };

    function loading() { return { type: LOADING_ITEM } }
    function loadingSuccess(item) { return { type: LOADING_ITEM_SUCCESS, [property]: item } }
    function loadingFailure(err) { return { type: LOADING_ITEM_FAILURE, error: err } }

    const getAction = (id) => {
        return (dispatch) => {
            dispatch(loading());
            return service.get(id)
                .then(data => {
                    const item = data[property];
                    dispatch(loadingSuccess(item));
                })
                .catch(err => {
                    dispatch(loadingFailure(err))
                });
        }
    };

    return { getReducer, getAction };
};

const listSlice = (service) => {
    const property = service.resources;

    const LOADING_ITEMS         = `pixoo/${property}/LOADING_ITEMS`;
    const LOADING_ITEMS_SUCCESS = `pixoo/${property}/LOADING_ITEMS_SUCCESS`;
    const LOADING_ITEMS_FAILURE = `pixoo/${property}/LOADING_ITEMS_FAILURE`;

    const listReducer = (state, action) => {
        switch (action.type) {
            case LOADING_ITEMS:
                return {
                    ...state,
                    isLoading: true,
                    hasLoadingError: false
                };
            case LOADING_ITEMS_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    hasLoadingError: false,
                    loadingErrorMessage: '',
                    [property]: action[property]
                };
            case LOADING_ITEMS_FAILURE:
                return {
                    ...state,
                    isLoading: false,
                    hasLoadingError: true,
                    loadingErrorMessage: action.error.message
                };
            default:
                return state;
        }
    };

    function loading() { return { type: LOADING_ITEMS } }
    function loadingSuccess(items) { return { type: LOADING_ITEMS_SUCCESS, [property]: items } }
    function loadingFailure(err) { return { type: LOADING_ITEMS_FAILURE, error: err } }
    const listAction = (filters) => {
        return (dispatch) => {
            dispatch(loading());
            return service.list(filters)
                .then(data => {
                    const items = data[property];
                    dispatch(loadingSuccess(items));
                })
                .catch(err => {
                    dispatch(loadingFailure(err))
                });
        }
    };

    return { listReducer, listAction };
}

const updateSlice = (service) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const UPDATING_ITEM         = `pixoo/${property}/UPDATING_ITEM`;
    const UPDATING_ITEM_SUCCESS = `pixoo/${property}/UPDATING_ITEM_SUCCESS`;
    const UPDATING_ITEM_FAILURE = `pixoo/${property}/UPDATING_ITEM_FAILURE`;

    const updateReducer = (state, action) => {
        switch (action.type) {
            case UPDATING_ITEM:
                return {
                    ...state,
                    isLoadingOne: true,
                    hasLoadingOneError: false
                };
            case UPDATING_ITEM_SUCCESS:
                return {
                    ...state,
                    isLoadingOne: false,
                    hasLoadingOneError: false,
                    [property]: action[property],
                    [listProperty]: (state[listProperty] || []).map(p => {
                        if (p.id === action[property].id)
                            return action[property];
                        return p;
                    })
                };
            case UPDATING_ITEM_FAILURE:
                return {
                    ...state,
                    isLoadingOne: false,
                    hasLoadingOneError: true,
                    loadingOneErrorMessage: action.error.message
                };
            default:
                return state;
        }
    };

    function updatingItem() { return { type: UPDATING_ITEM } }
    function updatingItemSuccess(item) { return { type: UPDATING_ITEM_SUCCESS, [property]: item } }
    function updatingItemFailure(err) { return { type: UPDATING_ITEM_FAILURE, error: err } }
    const updateAction = item => {
        return (dispatch) => {
            dispatch(updatingItem());
            return service.update(item)
                .then(data => {
                    const item = data[property];
                    dispatch(updatingItemSuccess(item));
                })
                .catch(err => {
                    dispatch(updatingItemFailure(err))
                });
        }
    };

    return { updateReducer, updateAction };
};

const destroySlice = (service) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const DELETING_ITEM         = `pixoo/${property}/DELETING_ITEM`;
    const DELETING_ITEM_SUCCESS = `pixoo/${property}/DELETING_ITEM_SUCCESS`;
    const DELETING_ITEM_FAILURE = `pixoo/${property}/DELETING_ITEM_FAILURE`;

    const deleteReducer = (state, action) => {
        switch (action.type) {
            case DELETING_ITEM:
                return {
                    ...state,
                    isDeleting: true,
                    hasDeleteError: false,
                    deleteErrorMessage: '',
                    [`${property}SuccessfullyCreated`]: false,
                    [`${property}SuccessfullyDeleted`]: false,
                };
            case DELETING_ITEM_SUCCESS:
                return {
                    ...state,
                    isDeleting: false,
                    hasDeleteError: false,
                    deleteErrorMessage: '',
                    [`${property}SuccessfullyDeleted`]: true,
                    [property]: null,
                    [listProperty]: (state[listProperty] || []).filter(p => p.id !== action[property])
                };
            case DELETING_ITEM_FAILURE:
                return {
                    ...state,
                    isDeleting: false,
                    hasDeleteError: true,
                    deleteErrorMessage: action.error.message,
                    [`${property}SuccessfullyDeleted`]: false,
                };
            default:
                return state;
        }
    };

    function deletingItem() { return { type: DELETING_ITEM } }
    function deletingItemSuccess(item) { return { type: DELETING_ITEM_SUCCESS, [property]: item } }
    function deletingItemFailure(err) { return { type: DELETING_ITEM_FAILURE, error: err } }
    const deleteAction = item => {
        return (dispatch) => {
            dispatch(deletingItem());
            return service.delete(item)
                .then(data => {
                    dispatch(deletingItemSuccess(item));
                })
                .catch(err => {
                    dispatch(deletingItemFailure(err))
                });
        }
    };

    return { deleteReducer, deleteAction };
};

export const createRestSlices = (RestService) => {
    return {
        initialState,
        ...startCreateSlice(RestService),
        ...createSlice(RestService),
        ...getSlice(RestService),
        ...listSlice(RestService),
        ...updateSlice(RestService),
        ...destroySlice(RestService)
    };
};

export const applyReducers = (state, action, reducers) => {
    return reducers.reduce(
        (state, reducer) => reducer(state, action),
        state
    );
};
