import BaseModel from './BaseModel';

/** Class representing an user. */
class Account extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for an accounts listing
 */
Account.getListingFields = () => [
    'id',
    'name',
    'iban',
    'currency',
    'beneficiary_name',
    'beneficiary_address'
];

export default Account;
