import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Role from '../../../../components/Role/Role';
import {
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import Icon from '@mdi/react';
import {
    mdiChartBarStacked,
    mdiSwapHorizontalBold,
    mdiCashMultiple,
    mdiAccountMultiple,
    mdiVideoInputComponent,
    mdiBriefcaseAccountOutline,
    mdiBank,
    mdiDomain,
    mdiPassportBiometric,
    mdiCreditCardOutline,
    mdiSemanticWeb,
    mdiDesktopMacDashboard
} from '@mdi/js';

import './Menu.css';

const Menu = () => {
    const { t }    = useTranslation();
    const { user } = useSelector(state => state.auth);

    return (
        <div className="Menu">
            <Nav navbar>
                <NavItem>
                    <NavLink to="/">
                        <Icon path={ mdiDesktopMacDashboard } size={ 1 } color="white" />
                        <span>{ t('menu.dashboard') }</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/reports/">
                        <Icon path={ mdiChartBarStacked } size={ 1 } color="white" />
                        <span>{ t('menu.reports') }</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/transactions/">
                        <Icon path={ mdiSwapHorizontalBold } size={ 1 } color="white" />
                        <span>{ t('menu.transactions') }</span>
                    </NavLink>
                </NavItem>
                <Role has={ user.superAdminRole() } >
                    <NavItem>
                        <NavLink to="/payments/">
                            <Icon path={ mdiCashMultiple } size={ 1 } color="white" />
                            <span>{ t('menu.payments') }</span>
                        </NavLink>
                    </NavItem>
                </Role>

                <Role has={ user.adminRole() } >
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <Icon path={ mdiVideoInputComponent } size={ 1 } color="white" />
                            <span>{ t('menu.setup') }</span>
                        </DropdownToggle>
                        <DropdownMenu>

                            <DropdownItem>
                                <NavItem>
                                    <NavLink to="/users/">
                                        <Icon path={ mdiAccountMultiple } size={ 1 } color="black" />
                                        <span>{ t('menu.users') }</span>
                                    </NavLink>
                                </NavItem>
                            </DropdownItem>

                            <Role has={ user.superAdminRole() } >
                                <DropdownItem>
                                    <NavItem>
                                        <NavLink to="/accounts">
                                            <Icon path={ mdiBriefcaseAccountOutline } size={ 1 } color="black" />
                                            <span>{ t('menu.accounts') }</span>
                                        </NavLink>
                                    </NavItem>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                    <NavItem>
                                        <NavLink to="/payment_systems">
                                            <Icon path={ mdiBank } size={ 1 } color="black" />
                                            <span>{ t('menu.payment_systems') }</span>
                                        </NavLink>
                                    </NavItem>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavItem>
                                        <NavLink to="/acquirers">
                                            <Icon path={ mdiDomain } size={ 1 } color="black" />
                                            <span>{ t('menu.acquirers') }</span>
                                        </NavLink>
                                    </NavItem>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavItem>
                                        <NavLink to="/devices">
                                            <Icon path={ mdiPassportBiometric } size={ 1 } color="black" />
                                            <span>{ t('menu.devices') }</span>
                                        </NavLink>
                                    </NavItem>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavItem>
                                        <NavLink to="/cardtypes">
                                            <Icon path={ mdiCreditCardOutline } size={ 1 } color="black" />
                                            <span>{ t('menu.card_types') }</span>
                                        </NavLink>
                                    </NavItem>
                                </DropdownItem>
                            </Role>

                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <Role has={ user.superAdminRole() } >
                        <NavItem>
                            <NavLink to="/batchs/">
                                <Icon path={ mdiSemanticWeb } size={ 1 } color="white" />
                                <span>{ t('menu.batchs') }</span>
                            </NavLink>
                        </NavItem>
                    </Role>
                </Role>

            </Nav>
        </div>
    );
}

export default Menu;
