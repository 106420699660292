import { Transactions } from '../../../services';

const GETTING_LAST         = `pixoo/transactions/GETTING_LAST`;
const GETTING_LAST_SUCCESS = `pixoo/transactions/GETTING_LAST_SUCCESS`;
const GETTING_LAST_FAILURE = `pixoo/transactions/GETTING_LAST_FAILURE`;

/* reducer */
const lastTransactionsReducer = (state, action) => {
    switch (action.type) {
            case GETTING_LAST:
                return {
                    ...state,
                    isGettingLastTransactions: true,
                    hasGettingLastTransactionsError: false
                };
            case GETTING_LAST_SUCCESS:
                return {
                    ...state,
                    isGettingLastTransactions: false,
                    hasGettingLastTransactionsError: false,
                    lastTransactions: action.lastTransactions
                };
            case GETTING_LAST_FAILURE:
                return {
                    ...state,
                    isGettingLastTransactions: false,
                    hasGettingLastTransactionsError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function gettingLastTransactions() { return { type: GETTING_LAST } }
function gettingLastTransactionsSuccess(lastTransactions) { return { type: GETTING_LAST_SUCCESS, lastTransactions: lastTransactions } }
function gettingLastTransactionsFailure(err) { return { type: GETTING_LAST_FAILURE, error: err } }
const getLastTransactionsAction = (filters) => {
    return (dispatch) => {
        dispatch(gettingLastTransactions());
        Transactions.getLastTransactions(filters)
            .then(data => {
                const { transactions } = data;
                dispatch(gettingLastTransactionsSuccess(transactions));
            })
            .catch(err => {
                dispatch(gettingLastTransactionsFailure(err))
            });
    }
}

/* Export reducer */
export default { lastTransactionsReducer,  getLastTransactionsAction };
