import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiBriefcaseEditOutline, mdiArrowLeftCircle } from '@mdi/js';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import AcquirerForm from '../AcquirerForm/AcquirerForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadAcquirer, updateAcquirer } from '../../../redux/modules/acquirers';

const AcquirerEdit = () => {
    const dispatch   = useDispatch();
    const { t }      = useTranslation();
    const { acquirerId } = useParams();

    useEffect(() => {
        dispatch(loadAcquirer(acquirerId))
    }, [dispatch, acquirerId])

     const actions = <Link to="/acquirers">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleAcquirerEdition = (values) => {
        dispatch(updateAcquirer(values));
    };

    return (
         <div className="AcquirerEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('acquirers.edit_title') }
                        icon={ <Icon path={ mdiBriefcaseEditOutline } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <AcquirerForm
                        onSubmit={ handleAcquirerEdition }
                        isCreation={ false }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default AcquirerEdit;
