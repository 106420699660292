import React from 'react';
import { useTranslation } from 'react-i18next';
import { Report } from '../../../../models';
import StyledTable from '../../../../components/StyledTable/StyledTable';

const ReportResultResume = ({ stats, currency }) => {
    const { t } = useTranslation();

    const formatStats = stats => Object.keys(stats).map(key => ({ ...stats[key], status: t(`reports.statuses.${key}`) }));

    const data = formatStats(stats.status)
    data.push({ nb: stats.nb, total: stats.total, status: t('reports.total') })

    const columns = [{
        dataField: 'status',
        text: t('reports.status')
    }, {
        dataField: 'nb',
        text: t('reports.number')
    }, {
        dataField: 'total.effective',
        text: t('reports.amount'),
        formatter: effective => Report.format_money(Math.abs(effective), currency)
    }];

    return (
        <StyledTable
            data={ data }
            columns={ columns }
            hideSearch
            hideExport
        />
    );
}

export default ReportResultResume;
