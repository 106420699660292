import RestClient from './RestClient';
import { Payment } from '../../models';

import _ from 'lodash';

class Payments extends RestClient {
    constructor() {
        super({
            model: Payment,
            entryPoint: 'payment',
            resource:   'payment',
            resources:  'payments',
            sortBy: 'payment_date'
        });
        this.ASK_PATH = '/ask';
    }

    ask(account_id, filters) {
        // remove empty filter values
        filters = _.pickBy((filters || {}), _.identity);
        return this.POST('/payment/ask', {
            account_id,
            ...filters
        }).then(response => response.json())
          .then(json => {
              if (json[this.resource]) {
                  json[this.resource] = new this.model(json[this.resource]);
                  return json;
              }
              throw new Error(json.flash.error);
          });
    }

    fixBalances(account_id) {
        return this.POST('/payment/fix_balances', { account_id })
            .then(async response => {
                const json = await response.json();
                if (response.status !== 200) {
                    throw new Error(json.flash?.error?.msg);
                }
                return json;
            });
    }
}

const PaymentsApi = new Payments();

export default PaymentsApi;
