import RestClient from './RestClient';
import { PaymentSystem } from '../../models';

class PaymentSystems extends RestClient {
    constructor() {
        super({
            model: PaymentSystem,
            entryPoint: 'payment_system',
            resource:   'payment_system',
            resources:  'payment_systems',
            sortBy: 'name'
        });
    }
}

const PaymentSystemsApi = new PaymentSystems();

export default PaymentSystemsApi;
