import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../../Dashboard/Dashboard';
import Accounts from '../../Accounts/Accounts';
import Payments from '../../Payments/Payments';
import PaymentSystems from '../../PaymentSystems/PaymentSystems';
import Acquirers from '../../Acquirers/Acquirers';
import Users from '../../Users/Users';
import Reports from '../../Reports/Reports';
import Transactions from '../../Transactions/Transactions';
import Devices from '../../Devices/Devices';
import CardTypes from '../../CardTypes/CardTypes';
import Batchs from '../../Batchs/Batchs';

import './MainContent.css';

const MainContent = () => {
    return (
        <div className="MainContent">
            <Switch>
                <Route path="/accounts"><Accounts /></Route>
                <Route path="/payments"><Payments /></Route>
                <Route path="/payment_systems"><PaymentSystems /></Route>
                <Route path="/acquirers"><Acquirers /></Route>
                <Route path="/users"><Users /></Route>
                <Route path="/reports"><Reports /></Route>
                <Route path="/transactions"><Transactions /></Route>
                <Route path="/devices"><Devices /></Route>
                <Route path="/cardtypes"><CardTypes /></Route>
                <Route path="/batchs"><Batchs /></Route>
                <Route path="/"><Dashboard /></Route>
            </Switch>
        </div>
    );
}

export default MainContent;
