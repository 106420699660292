import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fixBalancesAction, resetFixingBalancesAction } from '../../redux/modules/payments';
import { Button, Badge } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiLoading, mdiScaleBalance } from '@mdi/js';

const FixBalancesButton = ({ accountId }) => {
    const { t }    = useTranslation();
    const { isFixingBalances, hasFixingBalancesError, fixingBalancesErrorMessage } = useSelector(state => state.payments);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetFixingBalancesAction());
    }, [dispatch]);

    const doFixBalances= () => {
        dispatch(fixBalancesAction(accountId, /*refresh*/true));
    };

    return (
        <>
            <Button color="dark" size="md" onClick={ doFixBalances }>
                { !isFixingBalances && (
                    <Icon path={ mdiScaleBalance } color="white" size={1} />
                )}
                { isFixingBalances && (
                    <Icon path={ mdiLoading } spin={ 1 } color="white" size={1} />
                )}
                {' '}{ t('financialHistory.fix_balances') }
            </Button>
            { hasFixingBalancesError && (
                <div>
                    <Badge color="danger">
                        <small>{ fixingBalancesErrorMessage }</small>
                    </Badge>
                </div>
            )}
        </>
    );
}

export default FixBalancesButton;
