import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiBriefcaseEditOutline, mdiArrowLeftCircle } from '@mdi/js';
import { ButtonGroup, Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import AccountForm from '../AccountForm/AccountForm';
import AccountRatesForm from '../AccountRatesForm/AccountRatesForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadAccount, updateAccount } from '../../../redux/modules/accounts';

const AccountEdit = () => {
    const [ activeTab, setActiveTab ] = useState('infos');

    const dispatch   = useDispatch();
    const { t }      = useTranslation();
    const { accountId } = useParams();

    useEffect(() => {
        dispatch(loadAccount(accountId))
    }, [dispatch, accountId])

     const actions = <Link to="/accounts">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleAccountEdition = (values) => {
        dispatch(updateAccount(values));
    };

    return (
         <div className="AccountEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('accounts.edit_title') }
                        icon={ <Icon path={ mdiBriefcaseEditOutline } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 6, offset: 3 }} md="6">
                    <Row>
                        <Col>
                            <ButtonGroup className="mb-3">
                                <Button onClick={() => { setActiveTab('infos') }} color={activeTab === 'infos' ? 'info' : 'dark' } size='sm'>
                                    { t('accounts.tab_infos') }
                                </Button>
                                <Button onClick={() => { setActiveTab('rates') }} color={activeTab === 'rates' ? 'info' : 'dark' } size='sm'>
                                    { t('accounts.tab_rates') }
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {activeTab === 'infos' && (
                        <AccountForm
                            onSubmit={ handleAccountEdition }
                            isCreation={ false }
                        />
                    )}
                    {activeTab === 'rates' && (
                        <AccountRatesForm accountId={ accountId } onSubmit={ handleAccountEdition } />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default AccountEdit;
