import React from 'react';
import { Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';

import './ErrorMessage.css';

const ErrorMessage = ({ message }) => {
    return (
        <div className="ErrorMessage">
            <Row className="justify-content-center">
                <Col className="align-self-center col-sm-auto">
                    <h3 className="alert alert-danger">
                        <Icon path={ mdiAlertCircleOutline } color="#721c24" size={ 3 } />
                        { message }
                    </h3>
                </Col>
            </Row>
        </div>
    );
}

export default ErrorMessage;
