import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getLastTransactions } from '../../../redux/modules/transactions';
import Loader from '../../../components/Loader/Loader';
import TransactionsListing from '../../../components/TransactionsListing/TransactionsListing';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Icon from '@mdi/react';
import { mdiSwapHorizontalBold, mdiShareAllOutline } from '@mdi/js';

import './LastTransactionsCard.css';

const LastTransactionsCard = ({ currency, accountId }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { isGettingLastTransactions, lastTransactions } = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch(getLastTransactions({
            'account_id': accountId
        }));
    }, [dispatch, accountId]);

    if (!lastTransactions || isGettingLastTransactions) {
        return <Loader />
    }

    return (
        <div className="LastTransactions">
            <Card className="p-3">
                <PageTitle
                    title={ t('transactions.last_title') }
                    icon={ <Icon path={ mdiSwapHorizontalBold } color="white" /> }
                    headingSize={4}
                />
                {lastTransactions.length > 0 &&
                    <TransactionsListing transactions={ lastTransactions } />
                }
                {lastTransactions.length === 0 &&
                    <h3 className="text-center">{ t('transactions.no_transactions_yet') }</h3>
                }
                <Link to={'/transactions'} className="text-right">
                    <Button color="info">
                        { t('transactions.see_all') } {' '}
                        <Icon path={ mdiShareAllOutline } color="white" size={1} />
                    </Button>
                </Link>
            </Card>
        </div>
    );
}

export default LastTransactionsCard;
