const SET_VALUES = 'pixoo/filters/SET_VALUES';

const initialState = {
    values: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_VALUES:
            return {
                ...state,
                values: action.values
            };
        default:
            return state;
    };
};

function setValues(values) { return { type: SET_VALUES, values } }
export function updateValues(values) {
    return (dispatch) => {
        dispatch(setValues(values));
    }
}
