import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PaymentCreate from './PaymentCreate/PaymentCreate';
import PaymentEdit from './PaymentEdit/PaymentEdit';
import PaymentsList from './PaymentsList/PaymentsList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Payments = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Payments">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><PaymentsList /></Route>
                    <Route exact path={`${path}/new`}><PaymentCreate /></Route>
                    <Route exact path={`${path}/edit/:paymentId`}><PaymentEdit /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default Payments;
