import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiCash, mdiArrowLeftCircle } from '@mdi/js';
import { Button, Row, Col, ButtonGroup } from 'reactstrap';
import Icon from '@mdi/react';
import PaymentForm from '../PaymentForm/PaymentForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PaymentNotify from '../../../components/PaymentNotify/PaymentNotify';
import { loadPayment, updatePayment, deletePayment } from '../../../redux/modules/payments';

const PaymentEdit = () => {
    const dispatch      = useDispatch();
    const { t }         = useTranslation();
    const { paymentId } = useParams();
    const [ activeTab, setActiveTab ] = useState('payment');
    const { payment } = useSelector(
        state => state.payments
    );
    const {
        paymentSuccessfullyDeleted
    } = useSelector(state => state.payments);

    useEffect(() => {
        dispatch(loadPayment(paymentId))
    }, [dispatch, paymentId])

    const actions = <Link to="/payments">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handlePaymentEdition = (values) => {
        dispatch(updatePayment(values));
    };

    const handleDeletePayment = (payment) => {
        dispatch(deletePayment(payment.id));
    }

    if (paymentSuccessfullyDeleted)
        return <Redirect to={ "/" } />;

    return (
         <div className="PaymentEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('payments.edit_title') }
                        icon={ <Icon path={ mdiCash } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            
            <Row>
                <Col xl={{ size: 6, offset: 3 }} md="6">
                    <Row>
                        <Col>
                            <ButtonGroup className="mb-3">
                                <Button onClick={() => { setActiveTab('payment') }} color={activeTab === 'payment' ? 'info' : 'dark' } size='sm'>
                                    { t('payments.tab_payment') }
                                </Button>
                                <Button onClick={() => { setActiveTab('notify') }} color={activeTab === 'notify' ? 'info' : 'dark' } size='sm'>
                                    { t('payments.tab_notify') }
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {activeTab === 'payment' && (
                        <PaymentForm
                            onSubmit={ handlePaymentEdition }
                            handleDelete={ handleDeletePayment }
                            isCreation={ false }
                        />
                    )}
                    {activeTab === 'notify' && (
                        <PaymentNotify payment={ payment } />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default PaymentEdit;
