import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AccountCreate from './AccountCreate/AccountCreate';
import AccountEdit from './AccountEdit/AccountEdit';
import AccountsList from './AccountsList/AccountsList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const Accounts = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Accounts">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><AccountsList /></Route>
                    <Route exact path={`${path}/new`}><AccountCreate /></Route>
                    <Route exact path={`${path}/edit/:accountId`}><AccountEdit /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default Accounts;
