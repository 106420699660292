import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading, mdiCashRemove } from '@mdi/js';
import Icon from '@mdi/react';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { Transaction } from '../../../models';

let TransactionForm = ({ isCreation, handleSubmit, handleEnable, handleDelete, pristine, submitting }) => {
    const { t }                  = useTranslation();
    const { accounts }           = useSelector((state) => state.accounts);
    const currentUser            = useSelector(state => state.auth.user);
    const { transaction, isLoading } = useSelector(state => state.transactions);
    const [currentAccount, setCurrentAccount] = useState(null);

    const onAccountChange = (e) => {
        const account_id = e.target.value;
        const account    = (accounts.filter(a => a.id === account_id) || [])[0];
        setCurrentAccount(account);
    }
    const isDeletable = transaction && !transaction.completed && currentUser.isSuperAdmin();

    return (
        <Form className="form" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('transactions.add_title') : t('transactions.edit_title') }</CardTitle>
                        </Col>
                        <Col className="text-right">
                            { !isCreation && isDeletable &&
                                <Button color="danger" onClick={ () => { handleDelete(transaction) } }>
                                    <Icon path={ mdiCashRemove } size={ 1 } color="white" /> { t('transactions.delete') }
                                </Button> }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    { isCreation &&
                        <FormGroup>
                            <Label for="account">{ t('transactions.account') }</Label>
                            <Field component="select" className="form-control" name="account_id" id="account_id" required onChange={ onAccountChange }>
                            { isCreation && <option>---</option> }
                            { accounts && accounts.map(account => <option key={ account.id } value={ account.id }>{ account.name }</option>) }
                            </Field>
                        </FormGroup>
                    }
                    { !isCreation &&
                        <FormGroup>
                            <Label for="account">{ t('transactions.account') }</Label>
                            <Field component="select" className="form-control" name="account" id="account" disabled required>
                                <option>{ transaction ? transaction.account.name : '-' }</option>
                            </Field>
                        </FormGroup>
                    }


                    <div className="row">
                        <FormGroup className="col-6">
                            <Label for="amount">{ t('transactions.amount') }</Label>
                            <Field component="input" className="form-control" placeholder={ t('transactions.amount') } name="amount" id="amount" required />
                        </FormGroup>

                        <FormGroup className="col-6">
                            <Label for="currency">{ t('transactions.currency') }</Label>
                            <Field component="select" className="form-control" placeholder={ t('transactions.currency') } name="currency" id="currency" required>
                            { Transaction.currencies().map(currency => <option key={ currency } value={ currency }>{currency}</option>) }
                            </Field>
                        </FormGroup>
                    </div>

                    <div className="row">
                        <FormGroup className="col-12">
                            <Label for="date">{ t('transactions.date') }</Label>
                            <Field
                                component={ DateTimePicker }
                                type="text"
                                name="date"
                                id="date"
                                timeFormat={ true }
                            />
                        </FormGroup>
                    </div>

                    <div className="row">
                        <FormGroup className="col-6">
                            <Label for="date">{ t('transactions.buyer') }</Label>
                            <Field
                                component="textarea"
                                className="form-control"
                                type="textarea"
                                name="buyer"
                                id="buyer"
                            />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="date">{ t('transactions.description') }</Label>
                            <Field
                                component="textarea"
                                className="form-control"
                                type="textarea"
                                name="description"
                                id="description"
                            />
                        </FormGroup>
                    </div>
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoading }>
                        { isLoading && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoading && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

TransactionForm = reduxForm({
    form: 'transactionForm',
    enableReinitialize: true,
})(TransactionForm)

TransactionForm = connect(
    state => ({
        initialValues: state.transactions.transaction
    })
)(TransactionForm)

export default TransactionForm;
