import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiCashPlus, mdiArrowLeftCircle } from '@mdi/js';
import { useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PaymentForm from '../PaymentForm/PaymentForm';
import { createPayment, startCreatePayment } from '../../../redux/modules/payments';

const PaymentCreate = () => {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const { t }    = useTranslation();

    const { payment, paymentSuccessfullyCreated } = useSelector(state => state.payments);

    useEffect(() => {
        dispatch(startCreatePayment());
    }, [dispatch]);

    if (paymentSuccessfullyCreated) {
        return <Redirect to={ `edit/${payment.id}` } />
    }

    const actions = <Link to={ path.replace('new', '') }>
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handlePaymentCreation = (values) => {
        dispatch(createPayment(values));
    };

    return (
        <div className="PaymentCreate">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('payments.add_title') }
                        icon={ <Icon path={mdiCashPlus} color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <PaymentForm onSubmit={ handlePaymentCreation } isCreation={ true } />
                </Col>
            </Row>
        </div>
    );
}

export default PaymentCreate;
