import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PaymentNotify = ({ payment }) => {
    const { t }         = useTranslation();
    const notifications = payment.notifications;

    return (
        notifications.map((notification) => 
            <div className="PaymentNotify">
                <p>{ t('common.by') } { notification.creator.fullname }, { t('common.the') } { moment(notifications.created_at).format('LLL') }.</p>
                <h5>{ notification.heading }</h5>
                <div dangerouslySetInnerHTML={{__html: notification.message }} />
                <hr  style={{
                    backgroundColor: '#fff',
                    height: .5,
                }}/>
            </div>
        )
    );
}

export default PaymentNotify;
