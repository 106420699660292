import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import users from './users';
import accounts from './accounts';
import payment_systems from './payment_systems';
import payments from './payments';
import acquirers from './acquirers';
import devices from './devices';
import card_types from './card_types';
import rates from './rates';
import transactions from './transactions';
import filters from './filters';
import batchs from './batchs';

export default combineReducers({
    form: formReducer,
    auth,
    users,
    accounts,
    payment_systems,
    payments,
    acquirers,
    devices,
    card_types,
    rates,
    transactions,
    filters,
    batchs,
});
