import RestClient from './RestClient';
import { Device } from '../../models';

class Devices extends RestClient {
    constructor() {
        super({
            model: Device,
            entryPoint: 'device',
            resource:   'device',
            resources:  'devices',
            sortBy: 'name'
        });
    }
}

const DevicesApi = new Devices();

export default DevicesApi;
