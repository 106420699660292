import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiPassportBiometric, mdiArrowLeftCircle } from '@mdi/js';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import DeviceForm from '../DeviceForm/DeviceForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadDevice, updateDevice } from '../../../redux/modules/devices';

const DeviceEdit = () => {
    const dispatch    = useDispatch();
    const { t }       = useTranslation();
    const { deviceId } = useParams();

    useEffect(() => {
        dispatch(loadDevice(deviceId))
    }, [dispatch, deviceId])

     const actions = <Link to="/devices">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleDeviceEdition = (values) => {
        dispatch(updateDevice(values));
    };

    return (
         <div className="DeviceEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('devices.edit_title') }
                        icon={ <Icon path={ mdiPassportBiometric } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <DeviceForm
                        onSubmit={ handleDeviceEdition }
                        isCreation={ false }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default DeviceEdit;
