import RestClient from './RestClient';
import { Account } from '../../models';

class Accounts extends RestClient {
    constructor() {
        super({
            model: Account,
            entryPoint: 'account',
            resource: 'account',
            resources: 'accounts',
            sortBy: 'name'
        });
    }

    updateRates(payload) {
        return this.PUT(
                `/${this.entryPoint}${this.UPDATE_PATH}/${payload[this.idField]}`,
                { [this.resource]: payload }
            ).then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const AccountsApi = new Accounts();

export default AccountsApi;
