import { useSelector } from 'react-redux';

const Role = ({has, not, children}) => {
    const { user } = useSelector((state) => state.auth);

    let result = false;
    let roles  = (has ? has : '').split(' ');

    for (let role of roles) {
        result = result || user.hasRole(role);
    }

    if(not){
        result = !result;
    }

    return result ? children : '';
}

export default Role;
