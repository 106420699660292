import { Transactions } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import balanceSlice from './transactions/balance';
import lastSlice from './transactions/last';
import revenuesStatsSlice from './transactions/revenuesStats';
import moment from 'moment';

const IMPORTING_ITEMS                   = `pixoo/transactions/IMPORTING_ITEMS`;
const IMPORTING_ITEMS_SUCCESS           = `pixoo/transactions/IMPORTING_ITEMS_SUCCESS`;
const IMPORTING_ITEMS_FAILURE           = `pixoo/transactions/IMPORTING_ITEMS_FAILURE`;
const GETTING_REPORT                    = `pixoo/transactions/GETTING_REPORT`;
const GETTING_REPORT_SUCCESS            = `pixoo/transactions/GETTING_REPORT_SUCCESS`;
const GETTING_REPORT_FAILURE            = `pixoo/transactions/GETTING_REPORT_FAILURE`;
const GETTING_MANAGERS                  = `pixoo/transactions/GETTING_MANAGERS`;
const GETTING_MANAGERS_SUCCESS          = `pixoo/transactions/GETTING_MANAGERS_SUCCESS`;
const GETTING_MANAGERS_FAILURE          = `pixoo/transactions/GETTING_MANAGERS_FAILURE`;
const REFUNDING                         = `pixoo/transactions/REFUNDING`;
const REFUNDING_SUCCESS                 = `pixoo/transactions/REFUNDING_SUCCESS`;
const REFUNDING_FAILURE                 = `pixoo/transactions/REFUNDING_FAILURE`;
const GETTING_FINANCIAL_HISTORY         = `pixoo/transactions/GETTING_FINANCIAL_HISTORY`;
const GETTING_FINANCIAL_HISTORY_SUCCESS = `pixoo/transactions/GETTING_FINANCIAL_HISTORY_SUCCESS`;
const GETTING_FINANCIAL_HISTORY_FAILURE = `pixoo/transactions/GETTING_FINANCIAL_HISTORY_FAILURE`;

const {
    initialState,
    createReducer, getReducer, listReducer,
    updateReducer, deleteReducer,
    startCreateAction, createAction, getAction, listAction,
    updateAction, deleteAction
} = createRestSlices(Transactions);

/* Transactions import reducer */
const importReducer = (state, action) => {
    switch (action.type) {
            case IMPORTING_ITEMS:
                return {
                    ...state,
                    isImporting: true,
                    hasImportingError: false,
                    importedTransactions: null,
                    importingErrorMessage: null
                };
            case IMPORTING_ITEMS_SUCCESS:
                return {
                    ...state,
                    isImporting: false,
                    hasImportingError: false,
                    importingErrorMessage: null,
                    importedTransactions: action.importedTransactions
                };
            case IMPORTING_ITEMS_FAILURE:
                return {
                    ...state,
                    isImporting: false,
                    hasImportingError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function importing() { return { type: IMPORTING_ITEMS } }
function importingSuccess(importedTransactions) { return { type: IMPORTING_ITEMS_SUCCESS, importedTransactions: importedTransactions} }
function importingFailure(err) { return { type: IMPORTING_ITEMS_FAILURE, error: err } }
const importAction = (data, managerId) => {
    return (dispatch) => {
        dispatch(importing());
        Transactions.importFromFile(data, managerId)
            .then(data => {
                const { transactions } = data;
                dispatch(importingSuccess(transactions));
            })
            .catch(err => {
                dispatch(importingFailure(err))
            });
    }
}

/* Report reducer */
const reportReducer = (state, action) => {
    switch (action.type) {
            case GETTING_REPORT:
                return {
                    ...state,
                    isGettingReport: true,
                    hasGettingReportError: false
                };
            case GETTING_REPORT_SUCCESS:
                return {
                    ...state,
                    isGettingReport: false,
                    hasGettingReportError: false,
                    report: action.report
                };
            case GETTING_REPORT_FAILURE:
                return {
                    ...state,
                    isGettingReport: false,
                    hasGettingReportError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function gettingReport() { return { type: GETTING_REPORT } }
function gettingReportSuccess(report) { return { type: GETTING_REPORT_SUCCESS, report: report } }
function gettingReportFailure(err) { return { type: GETTING_REPORT_FAILURE, error: err } }
const getReportAction = (filters) => {
    return (dispatch) => {
        dispatch(gettingReport());
        Transactions.getReport(filters)
            .then(data => {
                const { report } = data;
                dispatch(gettingReportSuccess(report));
            })
            .catch(err => {
                dispatch(gettingReportFailure(err))
            });
    }
}

/* Managers reducer */
const managersReducer = (state, action) => {
    switch (action.type) {
            case GETTING_MANAGERS:
                return {
                    ...state,
                    isGettingManagers: true,
                    hasGettingManagersError: false
                };
            case GETTING_MANAGERS_SUCCESS:
                return {
                    ...state,
                    isGettingManagers: false,
                    hasGettingManagersError: false,
                    managers: action.managers
                };
            case GETTING_MANAGERS_FAILURE:
                return {
                    ...state,
                    isGettingManagers: false,
                    hasGettingManagersError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function gettingManagers() { return { type: GETTING_MANAGERS } }
function gettingManagersSuccess(managers) { return { type: GETTING_MANAGERS_SUCCESS, managers: managers } }
function gettingManagersFailure(err) { return { type: GETTING_MANAGERS_FAILURE, error: err } }
const getManagersAction = () => {
    return (dispatch) => {
        dispatch(gettingManagers());
        Transactions.getManagers()
            .then(data => {
                const { managers } = data;
                dispatch(gettingManagersSuccess(managers));
            })
            .catch(err => {
                dispatch(gettingManagersFailure(err))
            });
    }
}

/* Refund reducer */
const refundReducer = (state, action) => {
    switch (action.type) {
            case REFUNDING:
                return {
                    ...state,
                    isRefunding: true,
                    hasRefundingError: false
                };
            case REFUNDING_SUCCESS:
                return {
                    ...state,
                    isRefunding: false,
                    hasRefundingError: false,
                };
            case REFUNDING_FAILURE:
                return {
                    ...state,
                    isRefunding: false,
                    hasRefundingError: true,
                    refundingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function refunding() { return { type: REFUNDING } }
function refundingSuccess() { return { type: REFUNDING_SUCCESS } }
function refundingFailure(err) { return { type: REFUNDING_FAILURE, error: err } }
const refundAction = (transaction, amount) => {
    return (dispatch) => {
        dispatch(refunding());
        Transactions.refund(transaction, amount)
            .then(data => {
                dispatch(refundingSuccess());
                dispatch(loadTransaction(transaction.id));
            })
            .catch(err => {
                dispatch(refundingFailure(err))
            });
    }
}

/* FinancialHistory reducer */
const financialHistoryReducer = (state, action) => {
    switch (action.type) {
            case GETTING_FINANCIAL_HISTORY:
                return {
                    ...state,
                    isGettingFinancialHistory: true,
                    hasGettingFinancialHistoryError: false
                };
            case GETTING_FINANCIAL_HISTORY_SUCCESS:
                return {
                    ...state,
                    isGettingFinancialHistory: false,
                    hasGettingFinancialHistoryError: false,
                    financialHistory: action.financialHistory
                };
            case GETTING_FINANCIAL_HISTORY_FAILURE:
                return {
                    ...state,
                    isGettingFinancialHistory: false,
                    hasGettingFinancialHistoryError: true,
                    importingErrorMessage: action.error.message
                };
        default:
            return state;
    }
};
function gettingFinancialHistory() { return { type: GETTING_FINANCIAL_HISTORY } }
function gettingFinancialHistorySuccess(financialHistory) { return { type: GETTING_FINANCIAL_HISTORY_SUCCESS, financialHistory: financialHistory } }
function gettingFinancialHistoryFailure(err) { return { type: GETTING_FINANCIAL_HISTORY_FAILURE, error: err } }
const getFinancialHistoryAction = (filters) => {
    return (dispatch) => {
        dispatch(gettingFinancialHistory());
        Transactions.getFinancialHistory(filters)
            .then(data => {
                const { financialHistory } = data;
                dispatch(gettingFinancialHistorySuccess(financialHistory));
            })
            .catch(err => {
                dispatch(gettingFinancialHistoryFailure(err))
            });
    }
}

const resetCreationReducer = (state, action) => {
    switch (action.type) {
        case 'pixoo/transaction/START_CREATING_ITEM':
            return {
                ...state,
                transaction: {
                    currency: 'CHF',
                    date: moment().toISOString()
                }
            }
        default:
            return state;
    }
};
/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        createReducer, getReducer, listReducer,
        updateReducer, deleteReducer, importReducer,
        reportReducer, managersReducer, refundReducer,
        financialHistoryReducer, balanceSlice.balanceReducer,
        lastSlice.lastTransactionsReducer,
        revenuesStatsSlice.revenuesStatsReducer,
        resetCreationReducer
    ]);
}

/* Export CRUD actions */
export const startCreateTransaction = startCreateAction;
export const createTransaction      = createAction;
export const loadTransaction        = getAction;
export const loadTransactions       = listAction;
export const updateTransaction      = updateAction;
export const deleteTransaction      = deleteAction;
export const importTransactions     = importAction;
export const getReport              = getReportAction;
export const getManagers            = getManagersAction;
export const refund                 = refundAction;
export const getFinancialHistory    = getFinancialHistoryAction;
export const getBalance             = balanceSlice.getBalanceAction;
export const getLastTransactions    = lastSlice.getLastTransactionsAction;
export const getRevenuesStats       = revenuesStatsSlice.getRevenuesStatsAction;
