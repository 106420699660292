import React, { useEffect } from 'react';
import ReportResult from './ReportResult/ReportResult';
import PageTitle from '../../components/PageTitle/PageTitle';
import FiltersForm from '../../components/FiltersForm/FiltersForm';
import Loader from '../../components/Loader/Loader';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { getReport } from '../../redux/modules/transactions';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { mdiSwapHorizontalBold } from '@mdi/js';
import { useTranslation } from 'react-i18next';

const Reports = () => {
    const dispatch = useDispatch();
    const filters  = useSelector(state => state.filters.values);
    const {
        report, isGettingReport,
        hasLoadingError, loadingErrorMessage
    } = useSelector(state => state.transactions);
    const { t }    = useTranslation();

    useEffect(() => {
        if (!filters.from || !filters.to)
            return;
        dispatch(getReport(filters));
    }, [dispatch, filters]);

    return (
        <div className="Reports">
            <PageTitle
                title={ t('reports.report') }
                icon={<Icon path={mdiSwapHorizontalBold} color="white" />}
            />

            <FiltersForm />

            {hasLoadingError && (
                <ErrorMessage message={ loadingErrorMessage } />
            )}

            {isGettingReport && (
                <Loader />
            )}

            {!isGettingReport && (
                <div className="mt-3">
                    <ReportResult
                        report={ report }
                    />
                </div>
            )}
        </div>
    );
}

export default Reports;
