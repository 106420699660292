import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PaymentSystemCreate from './PaymentSystemCreate/PaymentSystemCreate';
import PaymentSystemEdit from './PaymentSystemEdit/PaymentSystemEdit';
import PaymentSystemsList from './PaymentSystemsList/PaymentSystemsList';
import { User } from '../../models';
import Role from '../../components/Role/Role';

const PaymentSystems = () => {
    const { path } = useRouteMatch();
    return (
        <div className="PaymentSystems">
            <Switch>
                <Role has={ User.ROLE_SUPER_ADMIN }>
                    <Route exact path={path}><PaymentSystemsList /></Route>
                    <Route exact path={`${path}/new`}><PaymentSystemCreate /></Route>
                    <Route exact path={`${path}/edit/:payment_systemId`}><PaymentSystemEdit /></Route>
                </Role>
            </Switch>
        </div>
    );
}

export default PaymentSystems;
