import { Batchs } from '../../services';
import { createRestSlices, applyReducers } from './rest';
import _ from 'lodash';

const RUNNING_BATCH          = `pixoo/batchs/RUNNING_BATCH`;
const RUNNING_BATCH_SUCCESS  = `pixoo/batchs/RUNNING_BATCH_SUCCESS`;
const RUNNING_BATCH_FAILURE  = `pixoo/batchs/RUNNING_BATCH_FAILURE`;

/* Batchs run reducer */
const runReducer = (state, action) => {
    switch (action.type) {
            case RUNNING_BATCH:
                return {
                    ...state,
                    isRunning: true,
                    runningBatchId: action.id,
                    hasRunningError: false,
                    runningErrorMessage: null
                };
            case RUNNING_BATCH_SUCCESS:
                return {
                    ...state,
                    isRunning: false,
                    hasRunningError: false,
                    runningErrorMessage: null,
                    runningBatchId: null,
                    batchs: _.remove(state.batchs, (b) => b.id !== state.runningBatchId)
                };
            case RUNNING_BATCH_FAILURE:
                return {
                    ...state,
                    isRunning: false,
                    hasRunningError: true,
                    runningBatchId: null,
                    runningErrorMessage: action.error.msg
                };
        default:
            return state;
    }
};
function running(id) { return { type: RUNNING_BATCH, id } }
function runningSuccess() { return { type: RUNNING_BATCH_SUCCESS}; }
function runningFailure(err) { return { type: RUNNING_BATCH_FAILURE, error: err } }
const runAction = (id) => {
    return (dispatch) => {
        dispatch(running(id));
        Batchs.run(id)
            .then(ok => {
                dispatch(runningSuccess());
            })
            .catch(err => {
                dispatch(runningFailure(err))
            });
    }
}

const {
    initialState,
    getReducer, listReducer,
    getAction, listAction,
} = createRestSlices(Batchs);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer,
        listReducer,
        runReducer
    ]);
}

/* Export CRUD actions */
export const loadBatch  = getAction;
export const loadBatchs = listAction;
export const runBatch   = runAction;
