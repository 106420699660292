import React, { useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiLoading, mdiCashPlus, mdiDotsHorizontal } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AskPaymentModal from '../AskPaymentModal/AskPaymentModal';

const AskPayment = ({ balance , askForPayment , accountId }) => {
    const { t }    = useTranslation();
    const { user } = useSelector(state => state.auth);

    const { financialHistory } = useSelector(state => state.transactions);
    const { isAskingPayment } = useSelector(state => state.payments);

    const [showModal, setShowModal] = useState(false);

    const payments    = financialHistory ? financialHistory.payments : [];
    const lastPayment = payments.length ? payments[payments.length - 1] : null;

    const hasPendingPayment = lastPayment && !lastPayment.completed;
    const canAskForPayment  = balance > 100 && (
        user.isAdmin() || (!user.isSuperAdmin() && user.account_id === accountId)
    );

    return (
        <>
            { hasPendingPayment && (
                <div className="AskPayment">
                    <h3>
                        { t('financialHistory.payment_pending_of') }{' '}
                        { lastPayment.amount }{' '}
                        { lastPayment.currency }
                    </h3>
                </div>
            )}

            { canAskForPayment && (
                <div className="AskPayment">
                    <ButtonGroup>
                        <Button color="info" onClick={ () => askForPayment({}) }>
                            { isAskingPayment && (
                                <Icon path={ mdiLoading } spin={ 1 } color="white" size={1} />
                            )}
                            { !isAskingPayment && (
                                <Icon path={ mdiCashPlus } color="white" size={1} />
                            )}
                            {' '} { t('dashboard.btn_new_payment') }
                        </Button>
                        <Button color="info" onClick={ () => setShowModal(true) }>
                            <Icon path={ mdiDotsHorizontal } color="white" size={1} />
                        </Button>
                    </ButtonGroup>
                    <AskPaymentModal
                        toggle={ () => setShowModal(!showModal) }
                        show={ showModal }
                        hide={() => setShowModal(false)}
                        askForPayment={ askForPayment }
                    />
                </div>
            )}
        </>
    );
}

export default AskPayment;
