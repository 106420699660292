import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Alert, Form, Button, FormGroup } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiLoading, mdiLoginVariant } from '@mdi/js';

import logo  from '../../../assets/images/Pixoo.png';
import './LoginForm.css';

let LoginForm = props => {
    const {
        handleSubmit,
        pristine,
        isLoggingIn,
        logInError,
        logInErrorMessage
    } = props;
    const { t } = useTranslation();

    return (
        <div className="LoginForm">
            { logInError && <Alert className="mt-4" color="danger">{ logInErrorMessage }</Alert> }
            <Form onSubmit={handleSubmit} className="form-login">
                <img className="logo" src={ logo } alt="logo" />
                <br/>
                <FormGroup>
                    <Field component="input" className="form-control" placeholder="Email" name="email" id="email" required />
                </FormGroup>
                <FormGroup>
                    <Field component="input" type="password" className="form-control" placeholder="Password" name="password" id="password" required />
                </FormGroup>
                <Button type="submit" color="dark" className="btn-block" disabled={pristine || isLoggingIn}>
                    { !isLoggingIn && <Icon path={ mdiLoginVariant } size={ 1 } color="white" /> }
                    { isLoggingIn && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                    &nbsp;{ t('login.connect') }
                </Button>
            </Form>
        </div>
    );
}

LoginForm = reduxForm({
    form: 'login'
})(LoginForm)

export default LoginForm;
