import RestClient from './RestClient';
import { CardType } from '../../models';

class CardTypes extends RestClient {
    constructor() {
        super({
            model: CardType,
            entryPoint: 'card_type',
            resource:   'card_type',
            resources:  'card_types',
            sortBy: 'name'
        });
    }
}

const CardTypesApi = new CardTypes();

export default CardTypesApi;
