import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiAccountGroup, mdiAccountPlus, mdiPencil } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { loadUsers } from '../../../redux/modules/users';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import StyledTable from '../../../components/StyledTable/StyledTable';
import Role from '../../../components/Role/Role';
import PageTitle from '../../../components/PageTitle/PageTitle';

const UsersList = () => {
    const dispatch    = useDispatch();
    const { path }    = useRouteMatch();
    const { t }       = useTranslation();
    const isLoading   = useSelector(state => state.users.isLoading);
    const users       = useSelector(state => state.users.users);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    if (!users || isLoading) {
        return <FullPageLoader />
    }

    const actionFormatter = (id, user) =>
        <Button tag={ Link } to={ `${path}/edit/${id}` } color="success"><Icon path={ mdiPencil } size={ 1 } color="white" /></Button>;

    const columns = [{
        dataField: 'fullname',
        text: t('users.fullname'),
        sort: true,
        formatter: (fullname) => <strong>{ fullname }</strong>
    }, {
        dataField: 'email',
        text: t('users.email'),
        sort: true
    }, {
        dataField: 'role',
        text: t('users.role'),
        sort: true
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }];

    const defaultSorted = [{
        dataField: 'fullname',
        order: 'desc'
    }];

    const actions =
        <Role has='admin'>
            <Link to={`${path}/new`}>
                <Button color="info">
                    <Icon path={ mdiAccountPlus } size={ 1 } color="white" /> { t('users.add') }
                </Button>
            </Link>
        </Role>

    return (
        <div className="UsersList">
            <PageTitle
                title={ t('users.list_title') }
                icon={ <Icon path={ mdiAccountGroup } color="white" /> }
                actions={ actions }
            />
            <StyledTable
                bootstrap4
                keyField="id"
                data={ users }
                columns={ columns }
                defaultSorted={ defaultSorted }
                sizePerPage= { 25 }
            />
        </div>
    );
}

export default UsersList;
