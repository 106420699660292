import RestClient from './RestClient';
import { User } from '../../models';

class Users extends RestClient {
    constructor() {
        super({
            model: User,
            entryPoint: 'user',
            resource: 'user',
            resources: 'users',
            sortBy: 'fullname'
        });

        this.ENABLE_PATH  = '/enable/id';
        this.DISABLE_PATH = '/disable/id';
    }

    disable(userId) {
        const url = `/${this.entryPoint}${this.DISABLE_PATH}/${userId}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    enable(userId) {
        const url = `/${this.entryPoint}${this.ENABLE_PATH}/${userId}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    update(data) {
        return this.PUT(
                `/${this.entryPoint}${this.UPDATE_PATH}/${data[this.idField]}`,
                { [this.resource]: data }
            ).then(response => response.json())
            .then(json => {
                if (json[this.resource]) {
                    json[this.resource] = new this.model(json[this.resource]);
                    return json;
                }

                throw new Error(json.flash.error);
            });
    }
}

const UsersApi = new Users();

export default UsersApi;
