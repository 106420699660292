import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiBank, mdiArrowLeftCircle } from '@mdi/js';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PaymentSystemForm from '../PaymentSystemForm/PaymentSystemForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPaymentSystem, updatePaymentSystem } from '../../../redux/modules/payment_systems';

const PaymentSystemEdit = () => {
    const dispatch   = useDispatch();
    const { t }      = useTranslation();
    const { payment_systemId } = useParams();

    useEffect(() => {
        dispatch(loadPaymentSystem(payment_systemId))
    }, [dispatch, payment_systemId])

     const actions = <Link to="/payment_systems">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handlePaymentSystemEdition = (values) => {
        dispatch(updatePaymentSystem(values));
    };

    return (
         <div className="PaymentSystemEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('payment_systems.edit_title') }
                        icon={ <Icon path={ mdiBank } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <PaymentSystemForm
                        onSubmit={ handlePaymentSystemEdition }
                        isCreation={ false }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default PaymentSystemEdit;
