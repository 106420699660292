import BaseModel from './BaseModel';

/** Class representing an user. */
class Acquirer extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for an acquirers listing
 */
Acquirer.getListingFields = () => [
    'id',
    'name',
    'payment_system_id',
    'payment_system.name',
    'customer_contract'
];

export default Acquirer;
