import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import PageTitle from '../../../components/PageTitle/PageTitle';
import FiltersForm from '../../../components/FiltersForm/FiltersForm';
import TransactionsResume from '../../../components/TransactionsResume/TransactionsResume';
import TransactionsTable from '../../../components/TransactionsTable/TransactionsTable';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { mdiCloudUploadOutline, mdiSwapHorizontalBold } from '@mdi/js';
import { mdiCashPlus } from '@mdi/js';
import { useRouteMatch, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { loadTransactions } from '../../../redux/modules/transactions';
import { askPaymentAction, resetAskPaymentAction } from '../../../redux/modules/payments';
import './TransactionsList.css';

const TransactionsList = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { path }     = useRouteMatch();
    const user         = useSelector(state => state.auth.user);
    const filters      = useSelector(state => state.filters.values);

    const { askedPayment } = useSelector(state => state.payments);

    const {
        transactions, isLoading,
        hasLoadingError, loadingErrorMessage
    } = useSelector(state => state.transactions);

    useEffect(() => {
        if (!filters.from || !filters.to)
            return;
        dispatch(loadTransactions(filters));
    }, [dispatch, filters]);

    useEffect(() => {
        if (!askedPayment)
            return;
        dispatch(resetAskPaymentAction());
    }, [dispatch, askedPayment]);

    if (askedPayment)
        return <Redirect to={ "/" } />;

    const askPayment = () => {
        dispatch(askPaymentAction(filters.account_id, filters));
    }

    const top_actions = user.isSuperAdmin() && (
        <div className="noPrint">
            <Link to={`${path}/new`}>
                <Button color="info">
                    <Icon path={mdiCashPlus} size={1} color="white" /> { t('transactions.add_title') }
                </Button>
            </Link>{' '}
            <Link to={`${path}/import`}>
                <Button color="info">
                    <Icon path={mdiCloudUploadOutline} size={1} color="white" /> { t('transactions.import_transactions') }
                </Button>
            </Link>
        </div>
    );

    return (
        <div className="TransactionsList">
            <PageTitle
                title="Transactions"
                icon={<Icon path={mdiSwapHorizontalBold} className="noPrint" color="white" />}
                actions={top_actions}
            />
            <FiltersForm />

            {hasLoadingError && (
                <ErrorMessage message={ loadingErrorMessage } />
            )}

            {isLoading && (
                <Loader />
            )}

            {!isLoading && (
                <div className="mt-3">
                    <TransactionsResume
                        transactions={transactions}
                        askPaymentButton={(
                            <Button color="info" disabled={ !filters.account_id } onClick={ askPayment }>
                                <Icon path={ mdiCashPlus } color="white" size={1} />
                                {' '} { t('dashboard.btn_new_payment') }
                            </Button>
                        )}
                    />
                    <TransactionsTable
                        transactions={ transactions }
                    />
                </div>
            )}
        </div>
    );
}

export default TransactionsList;
