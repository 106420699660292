import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { authenticate } from '../../redux/modules/auth';
import LoginForm from './LoginForm/LoginForm';

import './Login.css';

const Login = () => {
    const authState = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const handleSubmit = (values) => {
        dispatch(authenticate(values.email, values.password));
    };

    return (
        <Container className="Login vh-100">
            <Row className="vh-100 justify-content-center">
                <Col className="align-self-center form-wrapper">
                    <LoginForm onSubmit={handleSubmit} {...authState} />
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
