import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import monitorReducersEnhancer from '../enhancers/monitorReducers';
import loggerMiddleware from '../middleware/logger';
import reducer from './modules/reducer';

function configureStore(preloadedState) {
    const middlewares = [loggerMiddleware, thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
    const composedEnhancers = compose(...enhancers);

    const store = createStore(reducer, preloadedState, composedEnhancers);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./modules/reducer', () => store.replaceReducer(reducer));
    }

    return store;
}

const store = configureStore();

export default store;
